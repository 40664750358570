/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "styles/theme";
import {
  Annotation,
  Btn,
  Container,
  ProductName,
  Title,
  Image,
} from "../styles";
import { gaEvent } from "utils/ga-utils";
import { useState } from "react";
import FreeTrialModal from "uis/free-trial";

export default function Section1() {
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <Container>
      <ProductName>Mile Visitors</ProductName>
      <Title>
        방문자 경험 개선부터
        <br />
        보안 강화까지 한 번에!
      </Title>
      <Annotation>
        Mile visitors는 방문자 출입 관리를 자동화로 기업의 보안을 강화합니다.
        <br />
        방문자 등록시, 자동으로 일정 알림과 오시는 길 상세 설명을 전송하여
        방문자 경험을 개선합니다.
      </Annotation>
      <Btn
        onClick={() => {
          gaEvent("Visitors-첫화면-무료로 시작하기");
          setOpenModal(true);
        }}
      >
        무료로 시작하기
      </Btn>
      <Image src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/visitors/main.png" />
      <FreeTrialModal
        isOpen={openModal}
        closeModal={() => setOpenModal(false)}
      />
    </Container>
  );
}
