/** @jsxImportSource @emotion/react */

import { useState } from "react";
import { css } from "@emotion/react";
import { theme } from "styles/theme";
import styled from "@emotion/styled";
import Logo from "uis/component/logo";
import CheckIcon from "uis/Icon/CheckIcon";
import Footer from "uis/component/footer";
import Form from "./form";
import CheckBox from "uis/checkbox";
import Button from "uis/button";

export const Header = styled.div`
    display: flex;
    height: 70px;
    align-items: center;
    background-color: white;
    padding-left: 40px;
`;

export const RootContainer = styled.div`
    padding: 90px 0 62px 0;
    background: radial-gradient(203.97% 141.42% at 100% 100%, #ebf5ff 0%, #fff 82%, #dceeff 100%);
    display: flex;
    justify-content: center;

    @media screen and (max-width: 1199px) and (min-width: 781px) {
        justify-content: start;
        padding-left: 50px;
        padding-bottom: 60px;
    }

    @media screen and (max-width: 780px) {
        padding: 80px 50px 60px 50px;
        justify-content: start;
    }
`;

export const IntroContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 780px) {
        width: 100%;
    }
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 40px;
    gap: 40px;

    border-radius: 20px;
    box-shadow: 0px 2px 24px 0px rgba(5, 28, 52, 0.12);

    @media screen and (max-width: 1199px) and (min-width: 781px) {
        margin-top: 40px;
        margin-right: 127px;
    }

    @media screen and (max-width: 780px) {
        margin: 40px 0;
        padding: 40px 24px;
    }
`;

export const ReferenceContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Title = styled.p`
    color: var(--Primary-600, #2074f9);
    font-size: 18px;
    font-weight: 700;
    line-height: 130%; /* 23.4px */
`;

export const MainContent = styled.h1`
    color: var(--Neutral-800, #26282b);
    font-size: 40px;
    font-weight: 700;
    line-height: 130%; /* 52px */
    white-space: pre-line;
    margin-top: 16px;
    margin-bottom: 32px;

    @media screen and (max-width: 780px) {
        white-space: normal;
    }
`;

export const Annotation = styled.p`
    color: var(--Neutral-600, #454c53);
    font-size: 16px;
    font-weight: 400;
    line-height: 150%; /* 24px */
    white-space: pre-line;
`;

export const CheckContainer = styled.div`
    display: flex;
    width: 410px;
    padding: 20px;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
    margin-bottom: 32px;

    border-radius: 10px;
    border: 1px solid var(--Neutral-400, #a0a8b0);
    background: var(--color-common-100, #fff);

    @media screen and (max-width: 780px) {
        background-color: transparent;
        border: none;
        padding: 0;
        margin-bottom: 0;
        width: 100%;
    }

    p {
        color: var(--Neutral-600, #454c53);
        font-size: 16px;
        font-weight: 400;
        line-height: 150%; /* 24px */
    }

    div {
        display: flex;
        gap: 4px;
        align-items: center;
    }
`;

export const References = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 16px;
    row-gap: 32px;
    margin-top: 32px;

    img {
        height: 24px;
        margin: 0 auto;
    }

    @media screen and (max-width: 1199px) {
        display: flex;
        flex-wrap: wrap;

        img {
            height: 24px;
            margin: 0;
        }
    }
`;

export const Line = styled.div`
    width: 100%;
    height: 1px;
    background: #a0a8b0;
    margin: 40px 0px;
`;

export const Link = styled.p`
    color: var(--Primary-600, #2074f9);
    font-size: 16px;
    font-weight: 600;
    line-height: 150%; /* 24px */
    text-decoration-line: underline;
    margin-top: 12px;
    cursor: pointer;
`;

export const GridContainer = styled.div`
    @media screen and (min-width: 1200px) {
        display: grid;
        grid-template-columns: 450px 560px;
        column-gap: 90px;
        grid-template-rows: 411px max-content;

        .item:nth-child(1) {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;
        }

        .item:nth-child(2) {
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 3;
        }

        .item:nth-child(3) {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 2;
            grid-row-end: 3;
        }
    }

    @media screen and (max-width: 1199px) and (min-width: 781px) {
        display: grid;
        width: 687px;
        grid-template-rows: max-content max-content max-content;

        .item:nth-child(1) {
            grid-row-start: 1;
            grid-row-end: 2;
        }

        .item:nth-child(2) {
            grid-row-start: 3;
            grid-row-end: 4;
        }

        .item:nth-child(3) {
            grid-row-start: 2;
            grid-row-end: 3;
        }
    }

    @media screen and (max-width: 780px) {
        display: grid;
        grid-template-rows: max-content max-content max-content;

        width: 100%;

        .item:nth-child(1) {
            grid-row-start: 1;
            grid-row-end: 2;
        }

        .item:nth-child(3) {
            grid-row-start: 3;
            grid-row-end: 4;
        }

        .item:nth-child(2) {
            grid-row-start: 2;
            grid-row-end: 3;
        }
    }
`;
