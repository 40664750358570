import styled from "@emotion/styled";

export const RootContainer = styled.div`
  background: radial-gradient(203.97% 141.42% at 100% 100%, #ebf5ff 0%, #fff 82%, #dceeff 100%);
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  width: 780px;

  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin: 0 auto;

  background: radial-gradient(203.97% 141.42% at 100% 100%, #ebf5ff 0%, #fff 82%, #dceeff 100%);

  @media screen and (max-width: 780px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  padding: 12px 20px;
  align-items: center;
  align-items: flex-start;
  gap: 3px;
  align-self: stretch;

  background-color: white;
`;

export const ContentContainer = styled.div`
  display: flex;
  padding: 40px 40px 120px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 56px;
  align-self: stretch;

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    align-self: stretch;
  }

  .event-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
  }

  .clients-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--opacity-8, 8px);
    align-self: stretch;
  }

  .clients-list {
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    .column {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      flex: 1 0 0;
    }
  }
`;

export const BoxContainer = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;

  border-radius: 10px;
  border: 1px solid rgba(160, 168, 176, 1);
  background: white;

  .row-item {
    display: flex;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch;
  }
`;

export const FormContainer = styled.div`
  border-radius: 20px;
  background: white;
  box-shadow: 0px 2px 24px 0px rgba(5, 28, 52, 0.12);

  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  align-self: stretch;

  .form-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
  }

  .input-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  }

  .checkbox-form {
    display: flex;
    align-items: center;
    gap: 4px;
    align-self: stretch;
  }
`;
