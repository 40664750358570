/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "styles/theme";
import { Card } from "../uis";

const Container = styled.div`
  background-color: ${theme.neutral50};
  padding: 120px 0;

  @media screen and (max-width: 800px) {
    padding: 80px 12px;
  }
`;

const FN_LIST = [
  {
    title: "구글 캘린더 연동",
    content:
      "구글 캘린더 연동을 통하여 회사 방문자와 일정을 조율한 경우 자동으로 마일 방문자 등록이 완료됩니다.",
    icon: (
      <img
        src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/visitors/functions/google.png"
        css={css`
          width: 32px;
          height: 32px;
        `}
      />
    ),
  },
  {
    title: "다수의 방문자 등록",
    content:
      "단체 방문의 경우 방문자 정보 엑셀 파일을 업로드 하면 한번에 다수의 방문자 등록을 할 수 있습니다.",
    icon: (
      <img
        src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/visitors/functions/visitors.png"
        css={css`
          width: 31.67px;
          height: 21px;
        `}
      />
    ),
  },
  {
    title: "테마 커스터마이징",
    content:
      "회사의 로고 및 컬러에 맞게 태블릿 화면을 커스터마이징 할 수 있습니다.",
    icon: (
      <img
        src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/visitors/functions/palette.png"
        css={css`
          width: 33.33px;
          height: 33.33px;
        `}
      />
    ),
  },
  {
    title: "카카오톡 리마인드",
    content:
      "방문자에게 방문 일정에 맞추어 리마인드 알림톡이 자동으로 발송되며, 회사 방문 경로에 대한 상세 안내를 포함합니다.",
    icon: (
      <img
        src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/visitors/functions/kakao.png"
        css={css`
          width: 32px;
          height: 32px;
        `}
      />
    ),
  },
  {
    title: "체크인/체크아웃 기능",
    content:
      "방문자의 방문 시점부터 방문 중, 체크 아웃한 시점까지 방문자의 상태를 트래킹하고 회사 내에 머문 시간 등을 기록할 수 있습니다.",
    icon: (
      <img
        src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/visitors/functions/frame_person.png"
        css={css`
          width: 33.33px;
          height: 33.33px;
        `}
      />
    ),
  },
  {
    title: "방문 목적 커스터마이징",
    content:
      "방문자의 방문 목적별로 부서 및 담당자에게 알림이 가며, 각각의 방문 목적은 회사의 필요에 따라 생성 및 삭제가 가능합니다.",
    icon: (
      <img
        src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/visitors/functions/customizing.png"
        css={css`
          width: 33.33px;
          height: 33.33px;
        `}
      />
    ),
  },
  {
    title: "초인종 기능",
    content:
      "방문자 등록 없이 방문한 경우나, 예정에 없던 방문 (배달 등)의 경우에는 초인종 기능을 통하여 담당자에게 알림이 가도록 설정할 수 있습니다.",
    icon: (
      <img
        src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/visitors/functions/notifications.png"
        css={css`
          width: 26.67px;
          height: 33.33px;
        `}
      />
    ),
  },
  {
    title: `더 많은 기능이\n추가될 예정입니다.`,
    content: "",
    icon: (
      <img
        src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/visitors/functions/more_horiz.png"
        css={css`
          width: 28px;
          height: 8px;
        `}
      />
    ),
  },
];
export default function Section3() {
  return (
    <Container>
      <div
        css={css`
          display: grid;
          width: 1060px;
          grid-template-columns: 1fr 1fr 1fr;
          column-gap: 20px;
          row-gap: 40px;
          margin: 0 auto;

          @media screen and (max-width: 800px) {
            grid-template-columns: 1fr 1fr;
            width: 100%;
            column-gap: 12px;
            row-gap: 16px;
          }
        `}
      >
        {FN_LIST.map((item, index) => {
          return (
            <Card title={item.title} content={item.content} icon={item.icon} />
          );
        })}
      </div>
    </Container>
  );
}
