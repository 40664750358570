/** @jsxImportSource @emotion/react */
import { Interpolation, Theme } from "@emotion/react";
import styled from "@emotion/styled";
import { useState } from "react";
import { theme } from "styles/theme";

type ButtonType = "primary" | "secondary" | "disabled" | "destructive";
type ButtonProps = {
    onClick?: () => void;
    type?: ButtonType;
    styles?: {
        text?: Interpolation<Theme>;
        container?: Interpolation<Theme>;
    };
    title: string;
    leftIcon?: ({ color, width, height }: { color?: string; width?: string; height?: string }) => JSX.Element;
};

const ButtonContainer = styled.div<{ type: "primary" | "secondary" | "disabled" | "destructive" }>`
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    display: flex;
    background-color: ${(props) =>
        props.type === "primary" ? theme.primary500 : props.type === "secondary" ? theme.primary50 : "#FFF6F6"};
    color: ${(props) => (props.type === "primary" ? "white" : props.type === "secondary" ? theme.primary500 : "#FF3E3E")};
    cursor: pointer;
    &:hover {
        background-color: ${(props) => (props.type === "primary" ? "#1065ED" : props.type === "secondary" ? theme.primary500 : "#FFDFDF")};
        color: ${(props) => props.type !== "destructive" && "white"};
    }
`;

const ButtonTitle = styled.p`
    font-weight: 700;
    font-size: 16px;
`;

export default function Button(props: ButtonProps) {
    const { type = "primary", styles, title, leftIcon, onClick = () => {} } = props;
    const [hover, setHover] = useState(false);
    return (
        <ButtonContainer
            type={type}
            css={styles?.container}
            onClick={onClick}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            {leftIcon &&
                leftIcon({
                    color: hover ? "white" : type === "primary" ? "white" : props.type === "secondary" ? theme.primary500 : "#FF3E3E",
                })}
            <ButtonTitle css={styles?.text}>{title}</ButtonTitle>
        </ButtonContainer>
    );
}
