/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";

export const Header = styled.div`
  display: flex;
  height: 70px;
  align-items: center;
  background-color: white;
  padding-left: 40px;
`;

export const RootContainer = styled.div`
  padding: 80px 0 120px 0;
  background: radial-gradient(
    203.97% 141.42% at 100% 100%,
    #ebf5ff 0%,
    #fff 82%,
    #dceeff 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 800px) {
    padding: 80px 20px;
    align-items: start;
  }
`;
