/** @jsxImportSource @emotion/react */

import { Interpolation, Theme, css } from "@emotion/react";
import styled from "@emotion/styled";
import { useEffect, useRef } from "react";
import { theme } from "styles/theme";

type Props = {
  styles?: {
    container?: Interpolation<Theme>;
    input?: Interpolation<Theme>;
  };
  onChange?: (value: string) => void;
  onChangeDebounce?: (value: string) => void;
  type?: "default" | "disabled" | "error";
  placeholder?: string;
  defaultValue?: string;
  errorText?: string;
  id?: string;
  onKeyDown?: (e: any) => void;
  allowMulti?: boolean;
};

const ErrorText = styled.p`
  margin-top: 12px;
  color: #ff6868;
  font-size: 14px;
  font-weight: 500;
`;
export default function InputField(props: Props) {
  const {
    styles,
    onChange = () => {},
    type = "default",
    placeholder,
    defaultValue,
    errorText,
    onChangeDebounce = () => {},
    id,
    onKeyDown = () => {},
    allowMulti,
  } = props;

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;

    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    }
  };

  useEffect(() => {
    if (allowMulti) {
      const textarea = textareaRef.current;
      if (textarea && placeholder) {
        textarea.value = placeholder;
        adjustTextareaHeight();
        textarea.value = "";
      }
    }
  }, [placeholder]);

  return (
    <div
      css={[
        css`
          display: flex;
          flex-direction: column;
        `,
        styles?.container,
      ]}
    >
      <textarea
        ref={textareaRef}
        rows={1}
        className={`select-input-${id}`}
        onChange={(e) => {
          onChange(e.target.value);
          onChangeDebounce(e.target.value);
        }}
        placeholder={placeholder}
        defaultValue={defaultValue}
        disabled={type === "disabled" ? true : false}
        onKeyDown={(e) => onKeyDown(e)}
        css={[
          css`
            display: flex;
            padding: 12px 16px;
            border-radius: 8px;
            background-color: var(--alias-color-background-normal-noraml);
            border: 1px solid var(--alias-color-line-normal);

            font-family: Pretendard;
            color: var(--alias-color-label-normal);
            font-size: 16px;
            font-weight: 400;
            line-height: 24px; /* 150% */
            letter-spacing: 0.16px;

            :focus {
              outline: none;
              border: 1px solid var(--global-color-coolneutral-70);
            }
            ::placeholder {
              color: var(--alias-color-label-assistive);
            }
          `,
          styles?.input,
          type === "disabled" && `color: ${theme.neutral200}`,
          type === "error" && "border: 1px solid #ff6868",
        ]}
      />
      {type === "error" && errorText && <ErrorText>{errorText}</ErrorText>}
    </div>
  );
}
