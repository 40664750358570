/** @jsxImportSource @emotion/react */

import { useState } from "react";
import { css } from "@emotion/react";
import { theme } from "styles/theme";
import styled from "@emotion/styled";

const ReviewContainer = styled.div`
    padding: 40px;
    background-color: white;
    border-radius: 24px;
    box-shadow: 4px 4px 16px 0px rgba(69, 76, 83, 0.1);
    height: 280px;
    width: 350px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 800px) {
        padding: 30px 20px;
    }
`;

const LogoContainer = styled.img`
    height: 24px;
    object-fit: contain;
    align-self: self-start;

    @media screen and (max-width: 800px) {
        height: 18px;
    }
`;

const Title = styled.p`
    color: ${theme.neutral900};
    font-size: 18px;
    font-weight: 700;
    line-height: 140%;

    margin-top: 24px;

    @media screen and (max-width: 800px) {
        font-size: 16px;
        margin-top: 12px;
    }
`;

const Article = styled.p`
    font-size: 16px;
    color: ${theme.neutral800};
    font-weight: 400;
    line-height: 150%;

    margin-top: 8px;
    flex: 1;

    @media screen and (max-width: 800px) {
        font-size: 12px;
    }
`;

const Annotation = styled.p`
    color: ${theme.neutral500};
    font-size: 16px;
    font-weight: 700;
    line-height: 120%; /* 19.2px */

    @media screen and (max-width: 800px) {
        font-size: 12px;
    }
`;

export const Review = ({
    logo,
    title,
    article,
    name,
    job,
}: {
    logo: string;
    title: string;
    article: string;
    name: string;
    job: string;
}) => {
    return (
        <ReviewContainer>
            <LogoContainer src={logo} />
            <Title>{title}</Title>
            <Article>{article}</Article>
            <Annotation>{name}</Annotation>
            <Annotation
                css={css`
                    margin-top: 4px;
                `}
            >
                {job}
            </Annotation>
        </ReviewContainer>
    );
};
