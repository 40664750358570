import React from "react";
import { IconProp } from ".";
export default function InfoIcon(props: IconProp) {
    const { width = 24, height = 24, color = "black" } = props;
    return (
        <div>
            <svg width={width} height={height} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="10" fill={color} />
                <path d="M12 11V16" stroke="white" strokeWidth="2" strokeLinecap="round" />
                <circle cx="12" cy="8" r="1" fill="white" />
            </svg>
        </div>
    );
}
