/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Button from "uis/button";

const Title = styled.p`
    color: white;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    line-height: 150%;

    @media screen and (max-width: 800px) {
        font-size: 20px;
    }
`;

const SubTitle = styled.p`
    color: white;
    font-size: 20px;
    font-weight: 500;
    line-height: 150%;
    text-align: center;
    margin-top: 10px;
    @media screen and (max-width: 800px) {
        font-size: 14px;
    }
`;

const Functions = styled.ul`
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    color: white;
    margin-top: 32px;

    @media screen and (max-width: 800px) {
        display: none;
    }
`;
export default function StartModal({
    isShow = false,
    onStart = () => {},
    title = "",
    functions = [],
}: {
    title?: string;
    isShow?: boolean;
    onStart?: () => void;
    functions?: string[];
}) {
    if (isShow)
        return (
            <div
                css={css`
                    border-radius: 30px;
                    background: rgba(0, 0, 0, 0.6);
                    backdrop-filter: blur(15px);
                    width: 1100px;
                    height: 600px;
                    position: absolute;
                    top: 0;

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    @media screen and (max-width: 800px) {
                        border-radius: 20px;
                        width: 100%;
                        height: 100%;
                    }
                `}
            >
                <Title>{title}</Title>
                <SubTitle
                    css={css`
                        @media screen and (max-width: 800px) {
                            display: none;
                        }
                    `}
                >
                    버튼을 클릭하여 기능들을 직접 체험해 보세요.
                </SubTitle>
                <SubTitle
                    css={css`
                        @media screen and (min-width: 800px) {
                            display: none;
                        }
                    `}
                >
                    모바일에서는 실행할 수 없는 기능입니다.
                </SubTitle>
                <Functions>
                    {functions.map((item, index) => (
                        <li key={item}>{item}</li>
                    ))}
                </Functions>
                <Button
                    onClick={onStart}
                    title="시작하기"
                    styles={{
                        container: css`
                            margin-top: 32px;
                            padding: 12px 16px;
                            width: 100px;

                            @media screen and (max-width: 800px) {
                                display: none;
                            }
                        `,
                    }}
                />
            </div>
        );
    else return <></>;
}
