/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";

export const FormContainer = styled.div`
  background-color: var(--global-color-common-100);
  border-radius: 20px;
  box-shadow: 0px 2px 24px 0px rgba(5, 28, 52, 0.12);
  width: 440px;
  padding: 40px;
  gap: 40px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 800px) {
    width: calc(100% - 40px);
    padding: 40px 20px;
    border-radius: 10px;
  }
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .label {
    color: var(--alias-color-label-normal);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.16px;
    gap: 4px;
    display: flex;
  }

  .caution {
    color: var(--alias-color-status-negative);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  .product-forms {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 12px;
  }

  .product-form {
    gap: 10px;
    display: flex;
    flex-direction: column;
  }

  .check-form {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  .product-label {
    color: var(--alias-color-label-normal);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  .form {
    padding-left: 28px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .question {
    color: var(--alias-color-label-noraml);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.16px;

    .highlight-text {
      color: var(--alias-color-primary-normal);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.16px;
    }

    .annotation {
      color: var(--alias-color-label-neutral);
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.14px;
      margin-left: 4px;
    }
  }
  .check-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .check-item {
    gap: 4px;
    display: flex;

    color: var(--alias-color-label-noraml);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: s 0.16px;
  }

  .boundary {
    background-color: var(--alias-color-line-normal);
    height: 1px;
  }

  .agree-check {
    display: flex;
    gap: 4px;
    align-items: center;

    color: var(--alias-color-label-neutral);
    font-size: 15px;
    font-weight: 400;
    line-height: 150%;

    .underline {
      cursor: pointer;
      font-weight: 600;
      text-decoration-line: underline;
    }
  }
`;
