import React from "react";
import { IconProp } from ".";
export default function RightArrowIcon(props: IconProp) {
    const { width = 24, height = 24, color = "black", onClick } = props;

    return (
        <div onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
                <path d="M15 5L8 12L15 19" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div>
    );
}
