import React from "react";
import { IconProp } from ".";
export default function BookIcon(props: IconProp) {
    const { width = 28, height = 22, color = "black", onClick } = props;

    if (color === "white") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 28 22" fill="none">
                <path
                    d="M11.3444 19.8172L2.81812 14.7797C2.31108 14.4801 2 13.935 2 13.3461C2 12.1118 3.29577 11.3066 4.40242 11.853L11.368 15.2928C12.0595 15.6343 12.8858 15.5478 13.4916 15.0704L22.2033 8.20424C23.4195 7.24568 25.2152 8.00085 25.3809 9.54053C25.4548 10.2278 25.163 10.9035 24.6121 11.321L13.5697 19.6893C12.9227 20.1797 12.0434 20.2302 11.3444 19.8172Z"
                    fill="#1B1D1F"
                    stroke="white"
                    strokeLinecap="round"
                />
                <path
                    d="M2.50614 8.82603L13.7354 0.698198C14.3298 0.267973 15.112 0.197772 15.7735 0.515289L25.0211 4.95413C26.3761 5.60454 26.5563 7.46123 25.3517 8.3601L13.6528 17.0896C13.0137 17.5665 12.1524 17.6182 11.4608 17.2211L2.68288 12.1806C1.42418 11.4578 1.33036 9.67708 2.50614 8.82603Z"
                    fill="white"
                />
                <path d="M15.3999 3.83038L20.2999 6.38398" stroke="#1B1D1F" strokeWidth="1.5" strokeLinecap="round" />
            </svg>
        );
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 28 22" fill="none">
            <path
                d="M11.3444 19.8172L2.81812 14.7797C2.31108 14.4801 2 13.935 2 13.3461C2 12.1118 3.29577 11.3066 4.40242 11.853L11.368 15.2928C12.0595 15.6343 12.8858 15.5478 13.4916 15.0704L22.2033 8.20424C23.4195 7.24568 25.2152 8.00085 25.3809 9.54053C25.4548 10.2278 25.163 10.9035 24.6121 11.321L13.5697 19.6893C12.9227 20.1797 12.0434 20.2302 11.3444 19.8172Z"
                fill="white"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M2.50614 8.82603L13.7354 0.698198C14.3298 0.267973 15.112 0.197772 15.7735 0.515289L25.0211 4.95413C26.3761 5.60454 26.5563 7.46123 25.3517 8.3601L13.6528 17.0896C13.0137 17.5665 12.1524 17.6182 11.4608 17.2211L2.68288 12.1806C1.42418 11.4578 1.33036 9.67708 2.50614 8.82603Z"
                fill={color}
            />
            <path d="M15.3999 3.83038L20.2999 6.38398" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
    );
}
