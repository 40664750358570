/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Modal from "react-modal";
import { theme } from "styles/theme";
import Icon from "uis/Icon";

const modalStyle = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        width: "350px",
        borderRadius: "20px",
        transform: "translate(-50%, -50%)",
    },
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        zIndex: 100,
    },
};
Modal.setAppElement("#root");

type Props = {
    modalIsOpen: boolean;
    closeModal: () => void;
};

export default function ChannelTalkModal(props: Props) {
    const { modalIsOpen, closeModal } = props;

    return (
        <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={modalStyle}>
            <div
                css={css`
                    display: flex;
                    justify-content: flex-end;
                `}
            >
                <Icon name="close" width={24} height={24} color={theme.neutral900} onClick={closeModal} />
            </div>
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 15px;
                `}
            >
                <iframe
                    src="https://mile.channel.io/home"
                    height={690}
                    width={380}
                    css={css`
                        border: none;
                        border-radius: 30px;
                    `}
                />
            </div>
        </Modal>
    );
}
