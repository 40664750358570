/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import MileMeeting from "./mile-meeting";
import MileVisitors from "./mile-visitors";
import { useEffect, useState } from "react";

export default function Section3() {
  return (
    <div
      css={css`
        padding-bottom: 200px;
        padding-top: 160px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        gap: 160px;

        @media screen and (max-width: 800px) {
          padding: 80px 12px;
          gap: 80px;
        }
      `}
    >
      <>
        <MileMeeting />
        <MileVisitors />
      </>
    </div>
  );
}
