/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "styles/theme";
import { BottomArrow, Card1Icon, Card2Icon, Card3Icon, Card4Icon } from "./icons";
import { MainData } from "data/main";

const ArticleContainer = styled.div`
  border-radius: 24px;
  background-color: ${theme.neutral100};
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  @media screen and (max-width: 800px) {
    height: max-content;
    padding: 12px;
    border-radius: 12px;
  }
`;

const Article = styled.p`
  color: ${theme.neutral500};
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 150%; /* 30px */
  white-space: pre-wrap;

  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
`;

const CardGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  row-gap: 40px;

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    column-gap: 0px;
    row-gap: 20px;
  }
`;

const CardContainer = styled.div`
  flex: 1;
  padding: 45px 45px;
  border-radius: 24px;
  background: #fff;
  box-shadow: 2px 2px 24px 0px rgba(69, 76, 83, 0.1);
  position: relative;
  height: 190px;

  @media screen and (max-width: 800px) {
    padding: 30px;
    border-radius: 12px;
    height: 100px;
  }
`;

const CardLabel = styled.p`
  color: ${theme.neutral900};
  font-size: 24px;
  font-weight: 700;
  line-height: 140%; /* 42px */
  white-space: pre-line;

  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
`;

const Container = styled.div`
  background-color: #f5f9fe;
  padding: 160px 0;

  @media screen and (max-width: 800px) {
    padding: 80px 0;
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 1204px;
  padding: 0 118px;
  margin: 0 auto;

  @media screen and (max-width: 800px) {
    width: calc(100% - 48px);
    padding: 0 24px;
  }
`;

const Title = styled.p`
  color: ${theme.neutral900};
  text-align: center;
  font-size: 48px;
  font-weight: 800;
  line-height: 140%; /* 67.2px */
  white-space: pre-wrap;

  @media screen and (max-width: 800px) {
    font-size: 20px;
  }
`;
export default function Section5() {
  const icons = [Card1Icon, Card2Icon, Card3Icon, Card4Icon];
  return (
    <Container>
      <SubContainer>
        <Title>{MainData.솔루션_강점.제목}</Title>

        <div
          css={css`
            flex: 1;
            display: flex;
            justify-content: center;
            padding: 40px 0;

            @media screen and (max-width: 800px) {
              padding: 20px;
            }
          `}
        >
          <BottomArrow />
        </div>

        <div
          css={css`
            display: flex;
            gap: 24px;
            margin-bottom: 80px;

            @media screen and (max-width: 800px) {
              margin-bottom: 40px;
              flex-direction: column;
            }
          `}
        >
          {MainData.솔루션_강점.장점_리스트_회색_박스.map((item, index) => (
            <ArticleContainer key={index}>
              <Article>{item}</Article>
            </ArticleContainer>
          ))}
        </div>

        <CardGroup>
          {MainData.솔루션_강점.장점_리스트_흰색_박스.map((item, index) => (
            <CardContainer key={index}>
              <CardLabel>{item}</CardLabel>
              {icons[index]()}
            </CardContainer>
          ))}
        </CardGroup>
      </SubContainer>
    </Container>
  );
}
