/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Content, Label, List, Title } from "./uis";
import { gaEvent } from "utils/ga-utils";
import styled from "@emotion/styled";
import { theme } from "styles/theme";
import Button from "uis/button";
import { MainData } from "data/main";

const ProductName = styled.p`
  color: ${theme.neutral600};
  font-size: 20px;
  font-weight: 700;
  line-height: 150%;
  margin-top: 55.5px;

  @media screen and (max-width: 800px) {
    font-size: 12px;
    margin-top: 0;
  }
`;
export default function MileVisitors() {
  return (
    <div
      css={css`
        display: flex;
        width: 1440px;
        margin: 0 auto;

        @media screen and (max-width: 800px) {
          width: 100%;
          flex-direction: column;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          padding-left: 170px;

          @media screen and (max-width: 800px) {
            padding: 0 12px;
          }
        `}
      >
        <ProductName>{MainData.서비스_리스트[1].제품_요약}</ProductName>
        <Label>{MainData.서비스_리스트[1].제품_이름}</Label>

        <Title>
          {MainData.서비스_리스트[1].제품_설명.split("\n")[0]}
          <br />
          {MainData.서비스_리스트[1].제품_설명.split("\n")[1]}
        </Title>

        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 80px;
          `}
        >
          <List>
            <HumanIcon />
            <Content>{MainData.서비스_리스트[1].제품_기능[0]}</Content>
          </List>
          <List>
            <AutoIcon />
            <Content>{MainData.서비스_리스트[1].제품_기능[0]}</Content>
          </List>
          <List>
            <SecurityIcon />
            <Content>{MainData.서비스_리스트[1].제품_기능[0]}</Content>
          </List>
        </div>
        <Button
          title="Visitors 더 알아보기"
          onClick={() => {
            gaEvent("Main-제품소개-Visitors 더 알아보기");
            window.location.href = "/product/visitors";
          }}
          styles={{
            container: css`
              box-shadow: 2px 2px 16px 0px rgba(69, 76, 83, 0.24);
              display: flex;
              padding: 16px 20px;
              justify-content: center;
              align-items: center;
              width: max-content;
              margin-top: 40px;

              @media screen and (max-width: 800px) {
                padding: 12px 16px;
              }
            `,
            text: css`
              font-size: 20px;
              font-weight: 700;
              @media screen and (max-width: 800px) {
                font-size: 16px;
              }
            `,
          }}
        />
      </div>
      <img
        src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/visitor-main.png"
        css={css`
          object-fit: contain;
          width: 424px;
          margin-left: 314px;

          @media screen and (max-width: 800px) {
            width: 80%;
            margin: 30px auto;
          }
        `}
      />
    </div>
  );
}

function HumanIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path
        d="M15 14.6995C13.4483 14.6995 12.127 14.154 11.0359 13.063C9.94492 11.972 9.39941 10.6506 9.39941 9.09899C9.39941 7.54737 9.94492 6.22899 11.0359 5.14385C12.127 4.05869 13.4483 3.51611 15 3.51611C16.5516 3.51611 17.873 4.05869 18.964 5.14385C20.055 6.22899 20.6005 7.54737 20.6005 9.09899C20.6005 10.6506 20.055 11.972 18.964 13.063C17.873 14.154 16.5516 14.6995 15 14.6995ZM4.39941 22.5881V21.7648C4.39941 20.9504 4.61114 20.1993 5.0346 19.5114C5.45806 18.8234 6.02486 18.2996 6.73501 17.94C8.06201 17.2824 9.41573 16.7863 10.7962 16.4516C12.1766 16.1169 13.5779 15.9495 15 15.9495C16.4456 15.9495 17.8587 16.1139 19.2391 16.4427C20.6195 16.7715 21.9615 17.2647 23.2649 17.9223C23.9751 18.2819 24.5419 18.8028 24.9654 19.4849C25.3888 20.1669 25.6005 20.9269 25.6005 21.7648V22.5881C25.6005 23.5111 25.2792 24.2939 24.6365 24.9366C23.9939 25.5793 23.211 25.9006 22.288 25.9006H7.71195C6.78892 25.9006 6.00608 25.5793 5.36341 24.9366C4.72075 24.2939 4.39941 23.5111 4.39941 22.5881Z"
        fill="#FF6060"
      />
    </svg>
  );
}

function AutoIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path
        d="M15 28.6373C13.5815 28.6373 12.378 28.1429 11.3893 27.1543C10.4006 26.1656 9.90624 24.962 9.90624 23.5435C9.90624 22.4249 10.2292 21.4305 10.875 20.5605C11.5208 19.6905 12.3437 19.0802 13.3437 18.7297V16.6563H8.97965C8.05662 16.6563 7.27378 16.335 6.63111 15.6923C5.98845 15.0496 5.66711 14.2668 5.66711 13.3438V11.5503H4.1984C3.73823 11.5503 3.34715 11.3893 3.02515 11.0673C2.70312 10.7453 2.54211 10.3542 2.54211 9.89407V3.64408C2.54211 3.18391 2.70312 2.79283 3.02515 2.47083C3.34715 2.1488 3.73823 1.98779 4.1984 1.98779H10.4484C10.9085 1.98779 11.2996 2.1488 11.6216 2.47083C11.9436 2.79283 12.1046 3.18391 12.1046 3.64408V9.89407C12.1046 10.3542 11.9436 10.7453 11.6216 11.0673C11.2996 11.3893 10.9085 11.5503 10.4484 11.5503H8.97965V13.3438H21.0204V11.2704C20.0204 10.9199 19.1975 10.3096 18.5516 9.43957C17.9058 8.56955 17.5829 7.57522 17.5829 6.45657C17.5829 5.03808 18.0772 3.83449 19.0659 2.84583C20.0546 1.85714 21.2582 1.36279 22.6766 1.36279C24.0951 1.36279 25.2987 1.85714 26.2874 2.84583C27.2761 3.83449 27.7704 5.03808 27.7704 6.45657C27.7704 7.57522 27.4475 8.56955 26.8017 9.43957C26.1558 10.3096 25.3329 10.9199 24.3329 11.2704V13.3438C24.3329 14.2668 24.0116 15.0496 23.3689 15.6923C22.7263 16.335 21.9434 16.6563 21.0204 16.6563H16.6563V18.7297C17.6563 19.0802 18.4792 19.6905 19.1251 20.5605C19.7709 21.4305 20.0938 22.4249 20.0938 23.5435C20.0938 24.962 19.5995 26.1656 18.6108 27.1543C17.6221 28.1429 16.4185 28.6373 15 28.6373Z"
        fill="#FFB800"
      />
    </svg>
  );
}

function SecurityIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path
        d="M13.6876 15.6805L12.0083 14.0189C11.7347 13.7453 11.4106 13.6115 11.0361 13.6174C10.6616 13.6233 10.3375 13.763 10.0639 14.0365C9.7904 14.3101 9.65363 14.6334 9.65363 15.0066C9.65363 15.3798 9.7904 15.7032 10.0639 15.9768L12.5123 18.4564C12.8448 18.7888 13.2365 18.955 13.6876 18.955C14.1387 18.955 14.5304 18.7888 14.8629 18.4564L19.9634 13.3558C20.237 13.0823 20.3738 12.753 20.3738 12.3681C20.3738 11.9831 20.237 11.6538 19.9634 11.3803C19.6899 11.1067 19.3606 10.97 18.9756 10.97C18.5907 10.97 18.2614 11.1067 17.9879 11.3803L13.6876 15.6805ZM15.0001 27.9401C14.8189 27.9401 14.6423 27.9267 14.4702 27.9C14.2981 27.8733 14.126 27.8332 13.9539 27.7797C11.059 26.8187 8.74284 25.0078 7.00551 22.347C5.26819 19.6862 4.39954 16.8622 4.39954 13.8749V8.1276C4.39954 7.43014 4.59768 6.80242 4.99397 6.24445C5.39027 5.68647 5.91042 5.28428 6.55444 5.03789L13.8425 2.31374C14.2166 2.17424 14.6025 2.10449 15.0001 2.10449C15.3977 2.10449 15.7836 2.17424 16.1577 2.31374L23.4458 5.03789C24.0898 5.28428 24.6099 5.68647 25.0062 6.24445C25.4025 6.80242 25.6007 7.43014 25.6007 8.1276V13.8749C25.6007 16.8622 24.732 19.6862 22.9947 22.347C21.2574 25.0078 18.9412 26.8187 16.0463 27.7797C15.8742 27.8332 15.7021 27.8733 15.53 27.9C15.3579 27.9267 15.1813 27.9401 15.0001 27.9401Z"
        fill="#0E8BFF"
      />
    </svg>
  );
}
