import React from "react";
import { IconProp } from ".";
export default function UserIcon(props: IconProp) {
    const { width = 24, height = 24, color = "black" } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 26 26" fill="none">
            <circle cx="13.0002" cy="7.58329" r="5.41667" fill={color} />
            <path
                d="M12.9985 23.8333H5.19143C4.11877 23.8333 3.25 22.9028 3.25 21.7581C3.25 19.6428 3.9001 17.4805 5.52534 16.2856C7.58202 14.7794 10.5636 14.5987 12.9985 14.6322C15.4364 14.5953 18.418 14.7794 20.4747 16.2856C22.0999 17.4772 22.75 19.6394 22.75 21.7581C22.75 22.9028 21.8812 23.8333 20.8086 23.8333H13.0015H12.9985Z"
                fill={color}
            />
        </svg>
    );
}
