//* www.mile.im의 컨텐츠 내용들입니다. 해당 영역이 수정되면, 랜딩페이지에 그대로 반영됩니다.

export const MainData = {
  메인_제목: "기업내 공간 관리\n데이터로 최적화하세요.",
  메인_부제목: "Mile은 데이터를 통해 오피스 내 공간을\n가장 효율적으로 사용할 수 있도록 돕습니다.",
  고객사_섹션_타이틀: "빠르게 성장하는 기업들은\nMile과 함께 변화된 근무환경을 경험하고 있습니다.",
  고객사_리스트: [
    "https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/idus.png",
    "https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/bagelcode.png",
    "https://mile-storage.s3.ap-northeast-2.amazonaws.com/lunit-logo.svg",
    "https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/kmong.png",
    "https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/greeting.png",
    "https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/jabis.png",
    "https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/munch-factory.png",
    "https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/fastlane.png",
    "https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/futureplay.webp",
    "https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/mashup-ventures.svg",
  ],
  서비스_리스트: [
    {
      제품_요약: "회의실 관리 솔루션",
      제품_이름: "Mile Meeting",
      제품_설명: "회의실 사용 경험 개선과\n운영 자동화!",
      제품_기능: ["회의실 관리/운영 자동화", "회의실 사용 경험 개선", "기업 공간 사용 최적화"],
    },
    {
      제품_요약: "방문자 관리 솔루션",
      제품_이름: "Mile Visitors",
      제품_설명: "방문자 경험 개선부터\n보안 강화까지 한 번에!",
      제품_기능: ["기업내 보안 강화", "방문자 관리 프로세스 자동화", "방문자 경험 개선"],
    },
  ],
  데이터_섹션: {
    작은_글씨: "더 정교한 공간 관리를 위해",
    큰_글씨: "공간 데이터를\n운영/관리의 인사이트로!",
  },
  솔루션_강점: {
    제목: "기존 구축형 제품의 문제들을\nCloud 기반 SaaS 솔루션으로 해결!",
    장점_리스트_회색_박스: [
      "초기 구축에 소모되는\n오랜 시간 및 비싼도입 비용",
      "도입 이후 관리\n프로세스 / 시스템의 부재",
      "높은 유지보수 비용, 제품\n자체의 낮은 유연성&자유도",
      "기능 중심의 레거시한 UI/UX",
    ],
    장점_리스트_흰색_박스: [
      "초기 구축에도 부담없는 빠른\n도입 시간과 적은 비용",
      "도입 이후 운영하며 얻을 수 있는\n공간 데이터 기반 인사이트",
      "기업별 정책에 맞는\n세부 기능 커스터마이징 기능",
      "모던하고 사용자 친화적인\nUI/UX 제공",
    ],
  },
  투자사_내용: "마일과 함께하는 믿음직한 파트너",
  하단_배너: {
    제목: "아직 고민하고 계신가요?",
    장점_리스트: ["설치부터 온보딩까지 1시간", "누구나 쉽게 사용 가능한 제품", "무료로 한 달 체험 가능"],
  },
};
