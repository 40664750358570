/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import Inquire from "../inquire";
import Section1 from "./section1";
import Section2 from "./section2";
import { theme } from "styles/theme";

export default function Analytics() {
    return (
        <>
            <Section1 />
            <Section2 />
            <div
                css={css`
                    padding: 120px 0 160px 0;
                    background-color: ${theme.neutral50};

                    @media screen and (max-width: 800px) {
                        padding: 0;
                        margin-bottom: 40px;
                    }
                `}
            >
                <Inquire ga="Analytics-고민완화-무료 체험하기" />
            </div>
        </>
    );
}
