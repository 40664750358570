import React from "react";
import { IconProp } from ".";
export default function InfoIcon(props: IconProp) {
    const { width = 38, height = 31, color } = props;
    return (
        <div>
            <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 2C6.34315 2 5 3.34315 5 5V20H4C3.44772 20 3 20.4477 3 21C3 21.5523 3.44772 22 4 22H5H19H20C20.5523 22 21 21.5523 21 21C21 20.4477 20.5523 20 20 20H19V5C19 3.34315 17.6569 2 16 2H8Z"
                    fill={color}
                />
                <rect x="8" y="7" width="3" height="2" rx="1" fill="white" fillOpacity="0.8" />
                <rect x="8" y="11" width="3" height="2" rx="1" fill="white" fillOpacity="0.8" />
                <rect x="8" y="15" width="3" height="2" rx="1" fill="white" fillOpacity="0.8" />
                <rect x="13" y="7" width="3" height="2" rx="1" fill="white" fillOpacity="0.8" />
                <rect x="13" y="11" width="3" height="2" rx="1" fill="white" fillOpacity="0.8" />
                <rect x="13" y="15" width="3" height="2" rx="1" fill="white" fillOpacity="0.8" />
            </svg>
        </div>
    );
}
