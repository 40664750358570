/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "styles/theme";
import Icon from "uis/Icon";

export default function Logo({ color = theme.primary500 }: { color?: string }) {
    return (
        <svg
            onClick={() => (window.location.href = "/")}
            width="100"
            height="39"
            viewBox="0 0 100 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            css={css`
                @media screen and (max-width: 800px) {
                    height: 28px;
                }
                cursor: pointer;
            `}
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.1489 9.22803H12.6794C9.13378 9.22803 6.25946 12.1023 6.25946 15.648V29.13C6.25946 32.6756 9.13378 35.5499 12.6794 35.5499H26.1614C27.3757 35.5499 28.5114 35.2128 29.4797 34.627L26.911 31.5868C26.6738 31.6591 26.4221 31.6979 26.1614 31.6979H12.6794C11.2612 31.6979 10.1114 30.5482 10.1114 29.13V15.648C10.1114 14.2297 11.2612 13.08 12.6794 13.08H22.1489V9.22803ZM26.1614 13.08C27.5796 13.08 28.7294 14.2297 28.7294 15.648V29.13C28.7294 29.3216 28.7084 29.5084 28.6686 29.6881L31.3695 32.8847C32.132 31.8288 32.5814 30.5319 32.5814 29.13V15.648C32.5814 12.1023 29.707 9.22803 26.1614 9.22803V13.08Z"
                fill={color}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.41998 7.30204C6.15922 7.30204 5.90754 7.34091 5.67041 7.41316L3.10165 4.37303C4.07001 3.78722 5.20562 3.45006 6.41998 3.45006H19.9019C23.4476 3.45006 26.3219 6.32438 26.3219 9.87004V23.352C26.3219 26.8976 23.4476 29.772 19.9019 29.772H9.95096V25.92H19.9019C21.3202 25.92 22.4699 24.7702 22.4699 23.352V9.87004C22.4699 8.45177 21.3202 7.30204 19.9019 7.30204H6.41998ZM5.93848 25.8749C4.75037 25.6495 3.85199 24.6057 3.85199 23.352V9.87004C3.85199 9.67837 3.87298 9.4916 3.91281 9.31191L1.21186 6.11534C0.449344 7.17118 0 8.46813 0 9.87004V23.352C0 26.7357 2.61771 29.508 5.93848 29.7542V25.8749Z"
                fill={color}
            />
            <path
                d="M38.0738 8.30438H43.9045L50.2053 23.696H50.4561L56.757 8.30438H62.5876V31H58.0109V16.1413H57.8228L51.8981 30.906H48.7634L42.8387 16.0786H42.6506V31H38.0738V8.30438ZM66.318 31V13.9783H70.9574V31H66.318ZM66.0986 9.40154C66.0986 8.08495 67.2427 7.0348 68.6377 7.01913C70.017 7.0348 71.1612 8.08495 71.1455 9.40154C71.1612 10.6868 70.017 11.7526 68.6377 11.7526C67.2427 11.7526 66.0986 10.6868 66.0986 9.40154ZM79.2018 8.30438V31H74.5624V8.30438H79.2018ZM90.5183 31.3448C85.2989 31.3291 82.1015 27.9593 82.1172 22.5675C82.1015 17.3168 85.3459 13.7589 90.3302 13.7589C94.7972 13.7589 98.3238 16.5801 98.3238 22.3794V23.696H86.6939C86.6939 26.2665 88.2142 27.8496 90.6123 27.8652C92.1954 27.8496 93.3239 27.1599 93.7784 26.1411H98.1984C97.5401 29.2916 94.7189 31.3291 90.5183 31.3448ZM86.6939 20.7807H93.9352C93.9195 18.7118 92.4932 17.2228 90.4243 17.2384C88.2926 17.2228 86.7879 18.8215 86.6939 20.7807Z"
                fill={color}
            />
        </svg>
    );
}
