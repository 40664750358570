/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";

export const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  p {
    color: var(--alias-color-primary-normal);
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.18px;
  }

  h1 {
    color: var(--alias-color-label-normal);
    font-size: 42px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: -1.26px;
  }

  @media screen and (max-width: 800px) {
    p {
      font-size: 14px;
    }

    h1 {
      font-size: 30px;
      line-height: 40px;
    }
  }
`;

export const CustomerSection = styled.div`
  margin-top: 40px;
  margin-bottom: 56px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  .article {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .title {
    color: var(--alias-color-label-normal);
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.48px;
  }

  .annotation {
    color: var(--alias-color-label-normal);
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
  }

  .customer-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 16px;
    row-gap: 32px;
    width: max-content;

    @media screen and (max-width: 800px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .customer-logo {
    width: 160px;
    height: 24px;
    object-fit: contain;
  }
`;
