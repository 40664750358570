/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import Inquire from "../inquire";
import Section1 from "./section1";
import Section2 from "./section2";
import Section3 from "./section3";

export default function Visitors({
  domain,
  title,
  content,
}: {
  domain?: string;
  title?: string;
  content?: string;
}) {
  return (
    <div>
      <Section1 />
      <Section2 />
      <Section3 />
      <div
        css={css`
          padding: 120px 0 160px 0;

          @media screen and (max-width: 800px) {
            padding: 0;
            padding-bottom: 40px;
          }
        `}
      >
        <Inquire ga={"Visitors-고민완화-무료 체험하기"} />
      </div>
    </div>
  );
}
