/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "styles/theme";
import MessageTrial from "./uis/message-trial";
import UI from "@mile-meeting/mile-ui";

const Container = styled.div`
  padding: 24px 153px 128px 170px;
  width: 1117px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 140px;

  @media screen and (max-width: 800px) {
    gap: 80px;
    width: 100%;
    padding: 24px 0;
  }
`;

const ArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (max-width: 800px) {
    gap: 12px;
  }

  h3 {
    color: ${theme.neutral900};
    font-size: 40px;
    font-weight: 700;
    line-height: 120%; /* 48px */
    letter-spacing: -0.4px;

    @media screen and (max-width: 800px) {
      font-size: 24px;
    }
  }

  .article {
    color: ${theme.neutral600};
    font-size: 18px;
    font-weight: 500;
    line-height: 150%; /* 30px */

    @media screen and (max-width: 800px) {
      font-size: 16px;
      margin-bottom: 24px;
    }
  }
`;

const SectionContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    margin: 0 12px;
    width: calc(100% - 24px);
  }
`;

const TrialText = styled.p`
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.22px;
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--alias-color-primary-normal);
  &:hover {
    color: var(--alias-color-primary-strong);
  }

  cursor: pointer;

  @media screen and (max-width: 800px) {
    font-size: 16px;
    margin-bottom: 24px;
  }
`;

const IconContainer = styled.div<{ isOpen?: boolean }>`
  display: flex;
  rotate: ${(props) => (props.isOpen ? "90deg" : "0")};
  transition: all 0.3s ease;
`;

export default function Section2() {
  const [open, setOpen] = useState<boolean>(true);
  const [hover, setHover] = useState<boolean>(false);

  return (
    <Container>
      <SectionContainer
        css={css`
          @media screen and (max-width: 800px) {
            flex-direction: column-reverse;
          }
        `}
      >
        <img
          src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/visitors/img1.png"
          css={css`
            width: 795px;
            height: 580px;
            position: absolute;
            top: 0;
            left: 0;

            @media screen and (max-width: 800px) {
              position: relative;
              width: 100%;
              height: max-content;
              max-width: 450px;
            }
          `}
        />
        <ArticleContainer
          css={css`
            margin-left: auto;
            margin-right: 17px;
            margin-top: 256px;
            margin-bottom: 162px;

            @media screen and (max-width: 800px) {
              margin: 0;
            }
          `}
        >
          <h3>방문 일정 등록</h3>
          <p className="article">
            방문객에게 방문 일정 입력 링크를 전달하거나,
            <br />
            담당자가 직접 방문객 정보를 입력하여
            <br />
            방문 일정을 등록해 보세요!
          </p>
        </ArticleContainer>
      </SectionContainer>
      <SectionContainer>
        <ArticleContainer
          css={css`
            margin-top: ${open ? "0" : "184px"};

            @media screen and (max-width: 800px) {
              margin: 0;
              margin-bottom: 24px;
            }
          `}
        >
          <h3>오시는 길 안내 메시지</h3>
          <p
            className="article"
            css={css`
              margin-bottom: 16px;
            `}
          >
            방문 일정이 등록되면, 방문객에게 방문 일정 안내가
            <br />
            자동으로 카카오톡/이메일로 전달됩니다.
          </p>
          <TrialText
            onClick={() => setOpen(!open)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            오시는 길 안내 메시지 직접 받아보기
            <IconContainer isOpen={open}>
              <UI.Icon
                name="arrowRight"
                size={24}
                color={
                  hover
                    ? `var(--alias-color-primary-strong)`
                    : `var(--alias-color-primary-normal)`
                }
              />
            </IconContainer>
          </TrialText>
          {open && <MessageTrial />}
        </ArticleContainer>
        <img
          src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/visitors/img2.png"
          css={css`
            width: 600px;
            height: 572px;
            margin: ${open ? "17px 0 17px 50px" : "0 0 0 97px"};

            @media screen and (max-width: 800px) {
              width: 100%;
              height: max-content;
              max-width: 450px;
              margin: 0;
            }
          `}
        />
      </SectionContainer>
      <SectionContainer
        css={css`
          @media screen and (max-width: 800px) {
            flex-direction: column-reverse;
          }
        `}
      >
        <img
          src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/visitors/img3.png"
          css={css`
            width: 614px;
            height: 658px;

            @media screen and (max-width: 800px) {
              width: 100%;
              height: max-content;
              max-width: 450px;
            }
          `}
        />
        <ArticleContainer
          css={css`
            margin-left: auto;
            margin-top: 243px;
            margin-bottom: 282px;

            @media screen and (max-width: 800px) {
              margin: 0;
            }
          `}
        >
          <h3>방문시 담당자 호출</h3>
          <p className="article">
            태블릿에서 안내 메시지 내 QR코드를 찍거나,
            <br />
            간단한 정보를 등록하여 담당자를 호출해 보세요!
          </p>
        </ArticleContainer>
      </SectionContainer>
      <SectionContainer>
        <ArticleContainer
          css={css`
            margin-top: 116px;
            margin-bottom: 188px;
            margin-right: 58px;
            @media screen and (max-width: 800px) {
              margin: 0;
            }
          `}
        >
          <h3>방문자 데이터 관리</h3>
          <p className="article">
            기업 내 방문자의 데이터를 한눈에 확인해 보세요.
            <br />
            방문 관련 인사이트를 편하게 시각화해 제공해 드려요.
          </p>
        </ArticleContainer>
        <img
          src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/visitors/img.png"
          css={css`
            width: 644px;
            height: 471px;

            @media screen and (max-width: 800px) {
              width: 100%;
              height: max-content;
              max-width: 450px;
            }
          `}
        />
      </SectionContainer>
    </Container>
  );
}
