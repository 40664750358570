import ReactGA from "react-ga4";

export const gaPageView = (page: string, title: string) => {
    ReactGA.send({
        hitType: "pageview",
        page: page,
        title: title,
    });
};

export const gaEvent = (name: string) => {
    ReactGA.event(name);
};
