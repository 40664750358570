import React from "react";
import { IconProp } from ".";
export default function LogoIcon(props: IconProp) {
    const { width = 38, height = 31, color = "white" } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 30 31" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.1132 6.17212H11.514C8.29424 6.17212 5.68408 8.78228 5.68408 12.0021V24.245C5.68408 27.4648 8.29424 30.075 11.514 30.075H23.757C24.8597 30.075 25.891 29.7688 26.7703 29.2368L24.4376 26.4761C24.2223 26.5417 23.9938 26.577 23.757 26.577H11.514C10.2261 26.577 9.18206 25.5329 9.18206 24.245V12.0021C9.18206 10.7142 10.2261 9.67009 11.514 9.67009H20.1132V6.17212ZM23.757 9.67009C25.0449 9.67009 26.0889 10.7142 26.0889 12.0021V24.245C26.0889 24.419 26.0699 24.5886 26.0337 24.7518L28.4864 27.6546C29.1789 26.6958 29.5869 25.5181 29.5869 24.245V12.0021C29.5869 8.78228 26.9768 6.17212 23.757 6.17212V9.67009Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.82996 4.42327C5.59317 4.42327 5.36461 4.45856 5.14928 4.52418L2.8166 1.76344C3.69596 1.23147 4.72721 0.925293 5.82996 0.925293H18.0729C21.2927 0.925293 23.9028 3.53545 23.9028 6.75525V18.9982C23.9028 22.218 21.2927 24.8281 18.0729 24.8281H9.03644V21.3302H18.0729C19.3608 21.3302 20.4049 20.2861 20.4049 18.9982V6.75525C20.4049 5.46733 19.3608 4.42327 18.0729 4.42327H5.82996ZM5.39271 21.2892C4.31379 21.0846 3.49798 20.1366 3.49798 18.9982V6.75525C3.49798 6.5812 3.51704 6.4116 3.55321 6.24842L1.10049 3.34563C0.408048 4.30443 0 5.48219 0 6.75525V18.9982C0 22.0709 2.37714 24.5884 5.39271 24.812V21.2892Z"
                fill={color}
            />
        </svg>
    );
}
