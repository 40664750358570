import React from "react";
import { IconProp } from ".";
export default function QuestionIcon(props: IconProp) {
    const { width = 24, height = 24, color = "black" } = props;
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
                <circle cx="12" cy="12" r="10" fill={color} />
                <path
                    d="M10.8906 13.8828C10.8975 12.1807 11.3555 11.6611 12.1758 11.1484C12.7637 10.7656 13.2217 10.3418 13.2148 9.71289C13.2217 9.0293 12.6885 8.58496 12.0254 8.57812C11.3896 8.58496 10.7949 9.00879 10.7676 9.80859H8.82617C8.86035 7.8877 10.2891 6.96484 12.0391 6.96484C13.9463 6.96484 15.293 7.95605 15.293 9.64453C15.293 10.7725 14.7119 11.4834 13.8164 12.0234C13.0439 12.4814 12.7021 12.9258 12.6953 13.8828V14.0332H10.8906V13.8828ZM10.6855 15.9746C10.6719 15.3457 11.1914 14.833 11.834 14.8398C12.4424 14.833 12.9688 15.3457 12.9688 15.9746C12.9688 16.6104 12.4424 17.123 11.834 17.123C11.1914 17.123 10.6719 16.6104 10.6855 15.9746Z"
                    fill="white"
                />
            </svg>
        </div>
    );
}
