import React from "react";
import { Header, RootContainer } from "./index.styles";
import Logo from "uis/component/logo";
import Footer from "uis/component/footer";
import { CustomerSection, TitleSection } from "./article.styles";

const ProposalSalesArticle = () => {
  return (
    <>
      <TitleSection>
        <p>도입 제안서</p>
        <h1>Mile이 우리 회사에 적합할까요?</h1>
      </TitleSection>
      <CustomerSection>
        <div className="article">
          <p className="title">Mile과 함께하는 고객사</p>
          <p className="annotation">
            마일과 함께 데이터 기반의 공간 운영을 하는 기업들입니다.
          </p>
        </div>
        <div className="customer-list">
          <img
            className="customer-logo"
            src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/idus.png"
          />
          <img
            className="customer-logo"
            src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/greeting.png"
          />
          <img
            className="customer-logo"
            src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/jabis.png"
          />
          <img
            className="customer-logo"
            src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/lunit-logo.svg"
          />
          <img
            className="customer-logo"
            src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/kmong.png"
          />
          <img
            className="customer-logo"
            src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/fastlane.png"
          />
          <img
            className="customer-logo"
            src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/bagelcode.png"
          />
          <img
            className="customer-logo"
            src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/mashup-ventures.svg"
          />
          <img
            className="customer-logo"
            src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/futureplay.webp"
          />
        </div>
      </CustomerSection>
    </>
  );
};

export default ProposalSalesArticle;
