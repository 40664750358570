import React from "react";
import { IconProp } from ".";
export default function CloseIcon(props: IconProp) {
    const { width = 24, height = 24, color = "black", onClick } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" onClick={onClick}>
            <path d="M16.8994 7L6.99992 16.8995" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 7L16.8995 16.8995" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}
