/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "styles/theme";
import { Annotation, Btn, Container, ProductName, Title, Image } from "../styles";
import { gaEvent } from "utils/ga-utils";
import { useState } from "react";
import FreeTrialModal from "uis/free-trial";
import { MeetingData } from "data/meeting";

export default function Section1() {
  const [openModal, setOpenModal] = useState<boolean>(false);
  return (
    <Container>
      <ProductName>{MeetingData.제품_이름}</ProductName>
      <Title>{MeetingData.제목}</Title>
      <Annotation>{MeetingData.설명}</Annotation>
      <Btn
        onClick={() => {
          gaEvent("Meeting-첫화면-무료로 시작하기");
          setOpenModal(true);
        }}
      >
        무료로 시작하기
      </Btn>
      <Image
        src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/meeting/main.png"
        css={css`
          margin-top: 99px;
          width: 1150px;
          height: 669px;
          margin-bottom: 56px;
        `}
      />
      <FreeTrialModal isOpen={openModal} closeModal={() => setOpenModal(false)} />
    </Container>
  );
}
