import React from "react";
import { IconProp } from ".";
export default function InfoIcon(props: IconProp) {
    const { width = 38, height = 31 } = props;
    return (
        <div>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Group 86">
                    <path
                        id="Union"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.5 1.25C13.5 0.559644 14.0596 0 14.75 0C15.4404 0 16 0.559644 16 1.25V2.75C16 2.83562 15.9914 2.91922 15.975 3H17C18.6569 3 20 4.34315 20 6V17C20 18.6569 18.6569 20 17 20H3C1.34315 20 0 18.6569 0 17V6C0 4.34315 1.34315 3 3 3H4.025C4.00861 2.91922 4 2.83562 4 2.75V1.25C4 0.559644 4.55964 0 5.25 0C5.94036 0 6.5 0.559644 6.5 1.25V2.75C6.5 2.83562 6.49139 2.91922 6.475 3H13.525C13.5086 2.91922 13.5 2.83562 13.5 2.75V1.25Z"
                        fill="white"
                        fillOpacity="0.5"
                    />
                    <rect id="Rectangle 326" x="4" y="7" width="12" height="2" rx="1" fill="white" fillOpacity="0.8" />
                </g>
            </svg>
        </div>
    );
}
