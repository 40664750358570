import React from "react";
import { Header, RootContainer } from "./index.styles";
import Logo from "uis/component/logo";
import Footer from "uis/component/footer";
import ProposalSalesArticle from "./article";
import ProposalSalesForm from "./form";

const ProposalSales = () => {
  return (
    <>
      <Header>
        <Logo />
      </Header>
      <RootContainer>
        <ProposalSalesArticle />
        <ProposalSalesForm />
      </RootContainer>
      <Footer />
    </>
  );
};

export default ProposalSales;
