export const REVIEW_DATA = [
    {
        logo: "https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/kmong.png",
        title: "마일 미팅 태블릿을 통해 회의실 사용 경험을 개선할 수 있었어요!",
        article:
            "부족한 회의실, 노쇼 문제 등 회의실 운영에 있어 어려움을 겪고 있었는데 마일 도입 후 태블릿을 통해 회의실 이용 현황을 확인 할 수 있으며, 캘린더를 통한 예약 없이 회의실에서 바로 예약/회의 연장이 가능해서 많은 구성원들이 만족하고 있습니다 🙂",
        name: "김지희",
        job: "GA/Office Manager",
    },
    {
        logo: "https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/greeting.png",
        title: "마일 미팅으로 회의실을 효율적으로 활용할 수 있게 되었습니다!",
        article:
            "도입 이전에는 현황 파악 및 노쇼 관리 등이 잘 안되어 공간을 효율적으로 활용 못하고 있었는데, 마일 도입 후에는 그동안 고민이 많았던 문제들을 해결하면서, 공간을 효율적으로 이용하는데 많은 도움이 됐습니다!",
        name: "엄기석",
        job: "General Affairs Manager",
    },
    {
        logo: "https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/mashup-ventures.svg",
        title: "마일 Visitors 서비스를 도입한 후, 마치 가상의 리셉션 데스크 직원이 함께하는 효과를 체감해요.",
        article:
            "방문객분들에 대한 상세하고 친절한 안내와 담당자 호출 프로세스를 통해, 저 뿐만 아니라 오피스에 찾아오시는 방문객분들까지도 모두 만족하고 잘 사용하고 있어요.",
        name: "정재원",
        job: "심사역",
    },
    {
        logo: "https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/futureplay.webp",
        title: "방문자 기록이 자동으로 아카이빙 되면서 보안 강화 고민을 해결할 수 있었어요.",
        article:
            "기존에 기록되지 않고 있던 방문자 기록을 자동으로 아카이빙 할 수 있게 되면서 보안을 강화 할 수 있게 되었어요. 스타트업들의 경우 회사 규모가 커지면서 보안상 방문자 관리가 필수인데, 마일 visitors를 통해 이를 수월하게 관리할 수 있어 추천합니다.",
        name: "장재근",
        job: "피플팀",
    },
];
