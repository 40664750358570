import { css } from "@emotion/react";
import { useState } from "react";
import Button from "uis/button";
import FreeTrialModal from "uis/free-trial";
import { gaEvent } from "utils/ga-utils";

const FreeTrialBtn = () => {
    const [showBtn, setShowBtn] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);

    window.addEventListener("scroll", (e) => {
        if (window.scrollY > 400) {
            setShowBtn(true);
        } else {
            setShowBtn(false);
        }
    });

    if (showBtn)
        return (
            <>
                <Button
                    title="무료 체험하기"
                    onClick={() => {
                        setOpenModal(true);
                        gaEvent("float-무료 체험하기 [모바일]");
                    }}
                    styles={{
                        container: css`
                            box-shadow: 0px 4px 20px 0px rgba(56, 133, 255, 0.3);
                            border-radius: 100px;
                            position: sticky;
                            width: 150px;
                            padding: 12px 0;
                            left: calc(50% - 75px);
                            bottom: 40px;
                            z-index: 2;

                            @media screen and (min-width: 800px) {
                                display: none;
                            }
                        `,
                    }}
                />
                <FreeTrialModal isOpen={openModal} closeModal={() => setOpenModal(false)} />
            </>
        );
    else <></>;
};

export default FreeTrialBtn;
