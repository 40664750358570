/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import UI from "@mile-meeting/mile-ui";
import { useState } from "react";
import { gaEvent } from "utils/ga-utils";
import { sendVisitGuide } from "utils/send-alimtalk";

const FormContainer = styled.div`
  border-radius: 16px;
  width: 402px;
  padding: 24px;
  background-color: var(--alias-color-background-normal-alternative);
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: 800px) {
    width: calc(100% - 48px);
    max-width: 402px;
  }
`;

const SuccessLabel = styled.p`
  font-size: 18px;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.18px;
  color: var(--alias-color-label-normal);
  text-align: center;
  white-space: pre-line;

  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
`;

const IconContainer = styled.div`
  box-shadow: 0px 2px 20px 0px rgba(56, 133, 255, 0.42);
  width: 46px;
  height: 46px;
  border-radius: 100%;
  position: absolute;
  top: 5px;
`;
const Label = styled.p`
  color: var(--alias-color-label-normal);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.16px;
  margin-bottom: 8px;

  span {
    margin-left: 4px;
    color: var(--alias-color-status-negative);
  }
`;

const RadioGroup = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
  color: var(--alias-color-label-normal);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.16px;

  @media screen and (max-width: 800px) {
    font-size: 14px;
    flex-direction: column;
    gap: 6px;
    align-items: start;
  }
`;

const Radio = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
`;

const CheckBox = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  font-size: 14px;
  color: var(--alias-color-label-alternative);
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.14px;
  text-decoration-line: underline;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const isValidEmail = (email: string) => {
  const regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
  return regex.test(email);
};

const isValidPhoneNumber = (number: string) => {
  const regex = /^010\d{8}$/;
  return regex.test(number);
};

const MessageTrial = () => {
  const [sns, setSns] = useState<"kakao" | "email">("kakao");
  const [email, setEmail] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [agree, setAgree] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const isAbleSend = () => {
    if (sns === "kakao") {
      return email &&
        isValidEmail(email) &&
        phoneNumber &&
        isValidPhoneNumber(phoneNumber) &&
        agree
        ? true
        : false;
    } else {
      return email && isValidEmail(email) && agree ? true : false;
    }
  };

  const onHandleSend = async () => {
    gaEvent("Visitors-제품소개-전송하기");
    setLoading(true);
    const res = await sendVisitGuide(sns, phoneNumber, email, agree);
    if (!res) {
      alert("전송에 실패했습니다.");
    }
    setSuccess(res);
    setLoading(false);
  };

  return (
    <FormContainer
      css={css`
        ${success && "gap: 24px;"}
        ${success && "padding: 40px 24px;"}
      `}
    >
      {success && (
        <>
          <div
            css={css`
              position: relative;
              display: flex;
              justify-content: center;
            `}
          >
            <UI.Icon
              name="checkCircle"
              size={56}
              color={`var(--alias-color-primary-normal)`}
            />
            <IconContainer></IconContainer>
          </div>
          <SuccessLabel>
            오시는 길 안내 메시지를 전송해 드렸어요!{"\n"}카카오톡 혹은 이메일을
            확인해주세요!
          </SuccessLabel>
          <UI.Button
            title="추가로 전송하기"
            onClick={() => {
              gaEvent("Visitors-제품소개-추가로 전송하기");
              setSuccess(false);
            }}
          />
        </>
      )}
      {!success && (
        <>
          <RadioGroup>
            <Radio onClick={() => setSns("kakao")}>
              <UI.Radio selected={sns === "kakao"} />
              <p>카카오톡으로 받기</p>
            </Radio>
            <Radio onClick={() => setSns("email")}>
              <UI.Radio selected={sns === "email"} />
              <p>이메일로 받기</p>
            </Radio>
          </RadioGroup>
          <InputContainer>
            <div>
              <Label>
                이메일<span>*</span>
              </Label>
              <UI.Input
                showHelpText={email && !isValidEmail(email) ? true : false}
                helpText={"이메일 형식이 올바르지 않습니다."}
                placeholder="ex) example@mile.im"
                width={"100%"}
                onChange={(value) => setEmail(value)}
                error={email && !isValidEmail(email) ? true : false}
              />
            </div>
            {sns === "kakao" && (
              <div>
                <Label>
                  전화번호<span>*</span>
                </Label>
                <UI.Input
                  showHelpText={
                    phoneNumber && !isValidPhoneNumber(phoneNumber)
                      ? true
                      : false
                  }
                  helpText={"전화번호 형식이 올바르지 않습니다."}
                  placeholder="ex) 01012345678"
                  width={"100%"}
                  onChange={(value) => {
                    setPhoneNumber(value);
                  }}
                  error={
                    phoneNumber && !isValidPhoneNumber(phoneNumber)
                      ? true
                      : false
                  }
                />
              </div>
            )}
          </InputContainer>
          <CheckBox>
            <UI.CheckBox
              selected={agree}
              onChange={(value) => setAgree(value)}
            />
            <p
              css={css`
                cursor: pointer;
              `}
              onClick={() =>
                window.open("https://policy.mile.im/landing-marketing.html")
              }
            >
              마케팅 활용 동의 및 광고 수신 동의
            </p>
          </CheckBox>
          <UI.Button
            title="전송하기"
            style={{ marginTop: "4px" }}
            disabled={!isAbleSend() || loading}
            onClick={onHandleSend}
          />
        </>
      )}
    </FormContainer>
  );
};

export default MessageTrial;
