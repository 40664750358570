/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import UI from "@mile-meeting/mile-ui";
import InputField from "uis/inputField";

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }

  span {
    color: var(--function-error, #ff3e3e);
    font-size: 16px;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    margin-left: 4px;
  }
`;
export default function Form({
  label,
  isEssential = false,
  placeholder,
  multi = false,
  error,
  onChange = () => {},
}: {
  multi?: boolean;
  label?: string;
  isEssential?: boolean;
  placeholder?: string;
  onChange?: (value: string | boolean) => void;
  error?: string;
}) {
  return (
    <FormContainer>
      <p>
        {label}
        {isEssential && <span>*</span>}
      </p>

      {!multi && (
        <UI.Input
          placeholder={placeholder}
          error={error ? true : false}
          helpText={error}
          onChange={onChange}
          showHelpText={error ? true : false}
        />
      )}

      {multi && (
        <InputField
          type={error ? "error" : "default"}
          errorText={error}
          onChange={onChange}
          allowMulti={multi}
          placeholder={placeholder}
          styles={{
            container: css``,
            input: css`
              font-size: 15px;
              font-weight: 400;
              height: auto;

              @media screen and (min-width: 780px) {
                ${multi && "height: 100px"};
              }
            `,
          }}
        />
      )}
    </FormContainer>
  );
}
