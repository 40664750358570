import React from "react";
import { IconProp } from ".";
export default function PenIcon(props: IconProp) {
    const { width = 24, height = 24, color = "black" } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 19 18" fill="none">
            <path
                d="M16.2212 5.65686L12.6857 2.12133L14.0999 0.707113C14.4904 0.316589 15.1236 0.316589 15.5141 0.707113L17.6354 2.82843C18.0259 3.21896 18.0259 3.85212 17.6354 4.24265L16.2212 5.65686Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.31397 15.3781C6.48173 15.3323 6.63465 15.2435 6.75762 15.1206L14.1572 7.72093L14.8071 7.07109L15.5142 6.36398L14.8071 5.65688L12.6858 3.53556L11.9787 2.82845L11.2715 3.53556L10.6217 4.18539L3.22209 11.585C3.09912 11.708 3.01034 11.8609 2.96452 12.0287L2.16754 14.947C1.96361 15.6937 2.64894 16.379 3.39566 16.1751L6.31397 15.3781Z"
                fill={color}
            />
        </svg>
    );
}
