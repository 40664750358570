/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Content, Label, List, Title } from "./uis";
import { gaEvent } from "utils/ga-utils";
import styled from "@emotion/styled";
import { theme } from "styles/theme";
import Button from "uis/button";
import { MainData } from "data/main";

const ProductName = styled.p`
  color: ${theme.neutral600};
  font-size: 20px;
  font-weight: 700;
  line-height: 150%;

  @media screen and (max-width: 800px) {
    font-size: 12px;
    margin-top: 0;
  }
`;

export default function MileMeeting() {
  return (
    <div
      css={css`
        display: flex;
        width: 1440px;
        margin: 0 auto;

        @media screen and (max-width: 800px) {
          width: 100%;
          flex-direction: column;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          padding-left: 170px;

          @media screen and (max-width: 800px) {
            padding: 0 12px;
          }
        `}
      >
        <ProductName>{MainData.서비스_리스트[0].제품_요약}</ProductName>
        <Label>{MainData.서비스_리스트[0].제품_이름}</Label>

        <Title>
          {MainData.서비스_리스트[0].제품_설명.split("\n")[0]}
          <br />
          {MainData.서비스_리스트[0].제품_설명.split("\n")[1]}
        </Title>

        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 80px;
          `}
        >
          <List>
            <MeetingIcon />
            <Content>{MainData.서비스_리스트[0].제품_기능[0]}</Content>
          </List>
          <List>
            <AutoIcon />
            <Content>{MainData.서비스_리스트[0].제품_기능[1]}</Content>
          </List>
          <List>
            <ExtendIcon />
            <Content>{MainData.서비스_리스트[0].제품_기능[2]}</Content>
          </List>
        </div>
        <Button
          title="Meeting 더 알아보기"
          onClick={() => {
            gaEvent("Main-제품소개-Meeting 더 알아보기");
            window.location.href = "/product/meeting";
          }}
          styles={{
            container: css`
              box-shadow: 2px 2px 16px 0px rgba(69, 76, 83, 0.24);
              display: flex;
              padding: 16px 20px;
              justify-content: center;
              align-items: center;
              width: max-content;
              margin-top: 40px;

              @media screen and (max-width: 800px) {
                padding: 12px 16px;
              }
            `,
            text: css`
              font-size: 20px;
              font-weight: 700;

              @media screen and (max-width: 800px) {
                font-size: 16px;
              }
            `,
          }}
        />
      </div>

      <img
        src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/meeting-main.png"
        css={css`
          object-fit: contain;
          width: 648px;
          margin-top: 39px;
          margin-left: 248px;

          @media screen and (max-width: 800px) {
            width: 100%;
            margin: 30px auto;
          }
        `}
      />
    </div>
  );
}

function MeetingIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path
        d="M20.8505 17.1005H19.0692C18.6924 17.1005 18.3742 17.2302 18.1147 17.4897C17.8552 17.7492 17.7255 18.0674 17.7255 18.4442C17.7255 18.8211 17.8552 19.1392 18.1147 19.3987C18.3742 19.6582 18.6924 19.788 19.0692 19.788H21.8817C22.3419 19.788 22.733 19.627 23.055 19.305C23.377 18.983 23.538 18.5919 23.538 18.1317V15.3192C23.538 14.9424 23.4082 14.6242 23.1487 14.3647C22.8892 14.1052 22.5711 13.9755 22.1942 13.9755C21.8174 13.9755 21.4992 14.1052 21.2397 14.3647C20.9802 14.6242 20.8505 14.9424 20.8505 15.3192V17.1005ZM9.14948 10.3995H10.9307C11.3075 10.3995 11.6257 10.2697 11.8852 10.0102C12.1447 9.75071 12.2745 9.43254 12.2745 9.05573C12.2745 8.67889 12.1447 8.36073 11.8852 8.10123C11.6257 7.84171 11.3075 7.71195 10.9307 7.71195H8.11823C7.65806 7.71195 7.26698 7.87296 6.94498 8.19498C6.62296 8.51698 6.46195 8.90806 6.46195 9.36823V12.1807C6.46195 12.5575 6.59171 12.8757 6.85123 13.1352C7.11073 13.3947 7.42889 13.5245 7.80573 13.5245C8.18254 13.5245 8.50071 13.3947 8.76023 13.1352C9.01973 12.8757 9.14948 12.5575 9.14948 12.1807V10.3995ZM5.21195 24.3505C4.28892 24.3505 3.50608 24.0292 2.86341 23.3865C2.22075 22.7439 1.89941 21.961 1.89941 21.038V6.46195C1.89941 5.53893 2.22075 4.75608 2.86341 4.11341C3.50608 3.47075 4.28892 3.14941 5.21195 3.14941H24.788C25.711 3.14941 26.4939 3.47075 27.1365 4.11341C27.7792 4.75608 28.1005 5.53893 28.1005 6.46195V21.038C28.1005 21.961 27.7792 22.7439 27.1365 23.3865C26.4939 24.0292 25.711 24.3505 24.788 24.3505H20.3179V25.1943C20.3179 25.6544 20.1569 26.0455 19.8349 26.3675C19.5129 26.6895 19.1218 26.8505 18.6617 26.8505H11.3383C10.8781 26.8505 10.4871 26.6895 10.165 26.3675C9.84304 26.0455 9.68204 25.6544 9.68204 25.1943V24.3505H5.21195Z"
        fill="#00DD80"
      />
    </svg>
  );
}

function AutoIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path
        d="M15 28.6373C13.5815 28.6373 12.378 28.143 11.3893 27.1543C10.4006 26.1656 9.90624 24.962 9.90624 23.5435C9.90624 22.4249 10.2292 21.4305 10.875 20.5605C11.5208 19.6905 12.3437 19.0802 13.3437 18.7297V16.6563H8.97965C8.05662 16.6563 7.27378 16.335 6.63111 15.6923C5.98845 15.0496 5.66711 14.2668 5.66711 13.3438V11.5503H4.1984C3.73823 11.5503 3.34715 11.3893 3.02515 11.0673C2.70312 10.7453 2.54211 10.3542 2.54211 9.89408V3.64408C2.54211 3.18391 2.70312 2.79283 3.02515 2.47083C3.34715 2.1488 3.73823 1.98779 4.1984 1.98779H10.4484C10.9085 1.98779 11.2996 2.1488 11.6216 2.47083C11.9436 2.79283 12.1046 3.18391 12.1046 3.64408V9.89408C12.1046 10.3542 11.9436 10.7453 11.6216 11.0673C11.2996 11.3893 10.9085 11.5503 10.4484 11.5503H8.97965V13.3438H21.0204V11.2704C20.0204 10.9199 19.1975 10.3096 18.5516 9.43958C17.9058 8.56955 17.5829 7.57522 17.5829 6.45658C17.5829 5.03808 18.0772 3.83449 19.0659 2.84583C20.0546 1.85714 21.2582 1.36279 22.6766 1.36279C24.0951 1.36279 25.2987 1.85714 26.2874 2.84583C27.2761 3.83449 27.7704 5.03808 27.7704 6.45658C27.7704 7.57522 27.4475 8.56955 26.8017 9.43958C26.1558 10.3096 25.3329 10.9199 24.3329 11.2704V13.3438C24.3329 14.2668 24.0116 15.0496 23.3689 15.6923C22.7263 16.335 21.9434 16.6563 21.0204 16.6563H16.6563V18.7297C17.6563 19.0802 18.4792 19.6905 19.1251 20.5605C19.7709 21.4305 20.0938 22.4249 20.0938 23.5435C20.0938 24.962 19.5995 26.1656 18.6108 27.1543C17.6221 28.143 16.4185 28.6373 15 28.6373Z"
        fill="#4462FF"
      />
    </svg>
  );
}

function ExtendIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path
        d="M4.80566 26.8505C4.34552 26.8505 3.95443 26.6895 3.63241 26.3675C3.31041 26.0455 3.14941 25.6544 3.14941 25.1943V17.6943C3.14941 17.2341 3.31041 16.843 3.63241 16.521C3.95443 16.199 4.34552 16.038 4.80566 16.038C5.26583 16.038 5.65691 16.199 5.97891 16.521C6.30094 16.843 6.46195 17.2341 6.46195 17.6943V21.2051L21.2051 6.46195H17.6943C17.2341 6.46195 16.843 6.30094 16.521 5.97891C16.199 5.65691 16.038 5.26583 16.038 4.80566C16.038 4.34552 16.199 3.95443 16.521 3.63241C16.843 3.31041 17.2341 3.14941 17.6943 3.14941H25.1943C25.6544 3.14941 26.0455 3.31041 26.3675 3.63241C26.6895 3.95443 26.8505 4.34552 26.8505 4.80566V12.3057C26.8505 12.7658 26.6895 13.1569 26.3675 13.4789C26.0455 13.8009 25.6544 13.9619 25.1943 13.9619C24.7341 13.9619 24.343 13.8009 24.021 13.4789C23.699 13.1569 23.538 12.7658 23.538 12.3057V8.79485L8.79485 23.538H12.3057C12.7658 23.538 13.1569 23.699 13.4789 24.021C13.8009 24.343 13.9619 24.7341 13.9619 25.1943C13.9619 25.6544 13.8009 26.0455 13.4789 26.3675C13.1569 26.6895 12.7658 26.8505 12.3057 26.8505H4.80566Z"
        fill="#CC7AFE"
      />
    </svg>
  );
}
