import React from "react";
import { IconProp } from ".";
export default function TopArrowIcon(props: IconProp) {
    const { width = 20, height = 20, color = "black", onClick } = props;
    return (
        <div onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
                <path
                    d="M4.16663 6.6665L9.99996 12.4998L15.8333 6.6665"
                    stroke={color}
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
}
