/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { MeetingData } from "data/meeting";
import { theme } from "styles/theme";

const Container = styled.div`
  padding: 200px 137px 142px 170px;
  width: 1133px;
  display: flex;
  flex-direction: column;
  gap: 160px;
  margin: 0 auto;

  @media screen and (max-width: 800px) {
    gap: 80px;
    width: 100%;
    padding: 24px 0;
  }
`;

const ArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (max-width: 800px) {
    gap: 12px;
  }

  h2 {
    color: ${theme.neutral900};
    font-size: 40px;
    font-weight: 700;
    line-height: 120%; /* 48px */
    letter-spacing: -0.4px;

    @media screen and (max-width: 800px) {
      font-size: 24px;
    }
  }

  p {
    color: ${theme.neutral600};
    font-size: 18px;
    font-weight: 500;
    line-height: 150%; /* 30px */

    @media screen and (max-width: 800px) {
      font-size: 16px;
      margin-bottom: 24px;
    }
  }
`;

const SectionContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    margin: 0 12px;
    width: calc(100% - 24px);
  }
`;

export default function Section2() {
  return (
    <Container>
      <SectionContainer
        css={css`
          @media screen and (max-width: 800px) {
            flex-direction: column-reverse;
          }
        `}
      >
        <img
          src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/meeting/img1.png"
          css={css`
            width: 697px;
            height: 815.5px;
            position: absolute;
            top: 0;
            left: 0;

            @media screen and (max-width: 800px) {
              position: relative;
              width: 100%;
              height: max-content;
              max-width: 450px;
            }
          `}
        />
        <ArticleContainer
          css={css`
            margin-left: auto;
            margin-right: 33px;
            margin-top: 138px;
            margin-bottom: 134px;
            white-space: pre-line;

            @media screen and (max-width: 800px) {
              margin: 0;
            }
          `}
        >
          <h2>{MeetingData.기능1.제목}</h2>
          <div>
            <p>{MeetingData.기능1.설명1}</p>
            <p
              css={css`
                margin-top: 12px;
              `}
            >
              {MeetingData.기능1.설명2}
            </p>
          </div>
        </ArticleContainer>
      </SectionContainer>
      <SectionContainer>
        <ArticleContainer
          css={css`
            margin: 176px 181px 176px 0;
            white-space: pre-line;

            @media screen and (max-width: 800px) {
              margin: 0;
            }
          `}
        >
          <h2>{MeetingData.기능2.제목}</h2>
          <div>
            <p>{MeetingData.기능2.설명1}</p>
            <p
              css={css`
                margin-top: 12px;
              `}
            >
              {MeetingData.기능2.설명2}
            </p>
          </div>
        </ArticleContainer>
        <img
          src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/meeting/img2.png"
          css={css`
            width: 630px;
            height: 560px;
            @media screen and (max-width: 800px) {
              position: relative;
              width: 100%;
              height: max-content;
              max-width: 450px;
            }
          `}
        />
      </SectionContainer>
      <SectionContainer
        css={css`
          @media screen and (max-width: 800px) {
            flex-direction: column-reverse;
          }
        `}
      >
        <img
          src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/meeting/img3.png"
          css={css`
            width: 680px;
            height: 516px;

            @media screen and (max-width: 800px) {
              position: relative;
              width: 100%;
              height: max-content;
              max-width: 450px;
            }
          `}
        />
        <ArticleContainer
          css={css`
            margin: 157px 0 121px 60px;
            white-space: pre-line;
            @media screen and (max-width: 800px) {
              margin: 0;
            }
          `}
        >
          <h2>{MeetingData.기능3.제목}</h2>
          <div>
            <p>{MeetingData.기능3.설명1}</p>
            <p
              css={css`
                margin-top: 12px;
              `}
            >
              {MeetingData.기능3.설명2}
            </p>
          </div>
        </ArticleContainer>
      </SectionContainer>
      <SectionContainer>
        <ArticleContainer
          css={css`
            margin: 127px 134px 216px 0;

            @media screen and (max-width: 800px) {
              margin: 0;
            }
          `}
        >
          <h2>{MeetingData.기능4.제목}</h2>
          <div>
            <p>{MeetingData.기능4.설명1}</p>
            <p
              css={css`
                margin-top: 12px;
              `}
            >
              {MeetingData.기능4.설명2}
            </p>
          </div>
        </ArticleContainer>
        <img
          src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/meeting/image4.png"
          css={css`
            width: 602px;
            height: 535px;

            @media screen and (max-width: 800px) {
              position: relative;
              width: 100%;
              height: max-content;
              max-width: 450px;
            }
          `}
        />
      </SectionContainer>
    </Container>
  );
}
