/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { MainData } from "data/main";
import { theme } from "styles/theme";
import { gaEvent } from "utils/ga-utils";

const Container = styled.div`
  background-color: ${theme.neutral800};
  @media screen and (max-width: 800px) {
    overflow: hidden;
  }
`;

const SubTitle = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 150%; /* 36px */
  color: white;

  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
`;

const Title = styled.p`
  font-size: 48px;
  font-weight: 800;
  line-height: 120%; /* 57.6px */
  color: white;
  margin-top: 16px;
  white-space: pre-wrap;

  @media screen and (max-width: 800px) {
    font-size: 30px;
  }
`;

const Btn = styled.button`
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 40px;
  margin-bottom: 340px;
  width: max-content;
  border: none;

  background-color: white;
  border-radius: 8px;

  color: ${theme.primary500};
  font-size: 20px;
  font-weight: 700;
  z-index: 1;

  cursor: pointer;

  @media screen and (max-width: 800px) {
    font-size: 16px;
    padding: 12px 16px;
  }
`;

export default function Section4() {
  return (
    <Container>
      <div
        css={css`
          width: 1270px;
          display: flex;
          flex-direction: column;
          margin: 0 auto;
          padding-top: 120px;
          padding-left: 170px;
          position: relative;

          @media screen and (max-width: 800px) {
            padding: 60px 0 0 24px;
            width: 100%;
          }
        `}
      >
        <SubTitle>{MainData.데이터_섹션.작은_글씨}</SubTitle>
        <Title>{MainData.데이터_섹션.큰_글씨}</Title>
        <Btn
          onClick={() => {
            gaEvent("Main-Mission2-상세보기");
            window.location.href = "/product/analytics";
          }}
        >
          상세보기
        </Btn>
        <img
          src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/m2-image.png"
          css={css`
            object-fit: contain;
            width: 604px;

            position: absolute;
            bottom: 0;
            left: 678px;

            @media screen and (max-width: 420px) {
              left: 12px;
              width: 400px;
            }

            @media screen and (min-width: 420px) and (max-width: 800px) {
              left: auto;
              right: 24px;
              width: 450px;
            }
          `}
        />
      </div>
    </Container>
  );
}
