import React from "react";
import { IconProp } from ".";
export default function InfoIcon(props: IconProp) {
    const { width = 38, height = 31, color = "black" } = props;

    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
                <circle cx="12" cy="12" r="10" fill="white" fillOpacity="0.5" />
                <path d="M12 7V12.5L17 14" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div>
    );
}
