import React from "react";
import { IconProp } from ".";
export default function InfoIcon(props: IconProp) {
    const { width = 22, height = 18, color = "black" } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 22 18" fill="none">
            <path
                d="M1 13V15C1 16.1046 1.89543 17 3 17H19C20.1046 17 21 16.1046 21 15V13"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7 6.83333L11 11M11 11L15 6.83333M11 11V1"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
