import React from "react";
import { IconProp } from ".";
export default function RefreshIcon(props: IconProp) {
    const { width = 24, height = 24, color = "black" } = props;
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.6004 7.45006C10.4368 7.97754 9.8765 8.27248 9.34902 8.10883L6.23396 7.14238C5.70648 6.97872 5.41154 6.41845 5.57519 5.89097L6.56397 2.70395C6.72762 2.17647 7.2879 1.88153 7.81538 2.04518C8.34286 2.20883 8.6378 2.7691 8.47415 3.29659L8.24464 4.03634C10.7731 2.84812 13.813 2.83051 16.4638 4.25668C20.5385 6.44887 22.2748 11.2884 20.6917 15.5034C20.4975 16.0204 19.9209 16.2821 19.4039 16.0879C18.8869 15.8937 18.6252 15.3172 18.8194 14.8002C20.0553 11.5097 18.6975 7.72948 15.5162 6.01796C13.4071 4.88322 10.9801 4.91792 8.98153 5.90077L9.94166 6.19865C10.4691 6.3623 10.7641 6.92258 10.6004 7.45006ZM13.6202 17.2025C13.7822 16.6745 14.3416 16.3779 14.8696 16.54L17.5896 17.375C17.7702 17.382 17.9487 17.4377 18.1039 17.5408C18.558 17.7441 18.7998 18.2587 18.65 18.7465L17.6708 21.9365C17.5087 22.4645 16.9493 22.7611 16.4214 22.599C15.8934 22.4369 15.5968 21.8775 15.7588 21.3496L15.9949 20.5805C13.4729 21.7537 10.4477 21.7651 7.80822 20.345C3.70816 18.1391 1.97559 13.2527 3.61038 9.01942C3.80934 8.50422 4.38828 8.24785 4.90349 8.44681C5.41869 8.64577 5.67506 9.22471 5.4761 9.73991C4.1999 13.0446 5.55465 16.8615 8.7558 18.5837C10.8382 19.7041 13.2304 19.6844 15.2142 18.7379L14.2827 18.4519C13.7547 18.2898 13.4581 17.7304 13.6202 17.2025Z"
                    fill={color}
                />
            </svg>
        </div>
    );
}
