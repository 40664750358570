/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "styles/theme";

export const Container = styled.div`
  padding-top: 200px;
  display: flex;
  flex-direction: column;
`;

export const ProductName = styled.p`
  color: ${theme.primary500};
  font-size: 24px;
  font-weight: 800;
  text-align: center;
  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
`;

export const Title = styled.h1`
  color: ${theme.neutral900};
  text-align: center;
  font-size: 60px;
  font-weight: 800;
  line-height: 120%; /* 72px */
  margin-top: 20px;
  white-space: pre-line;
  @media screen and (max-width: 800px) {
    font-size: 30px;
  }
`;

export const Annotation = styled.p`
  color: ${theme.neutral600};
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 150%; /* 27px */
  margin-top: 24px;
  white-space: pre-line;

  @media screen and (max-width: 800px) {
    font-size: 12px;
    margin: 24px 12px 0 12px;
  }
`;

export const Btn = styled.button`
  border: none;
  display: flex;
  padding: 16px 20px;
  width: max-content;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  border-radius: 8px;
  background: ${theme.neutral600};
  box-shadow: 4px 4px 16px 0px rgba(69, 76, 83, 0.4);

  color: white;
  font-size: 16px;
  font-weight: 700;

  @media screen and (max-width: 800px) {
    font-size: 14px;
    padding: 12px 16px;
  }
`;

export const Image = styled.img`
  width: 1169px;
  height: 690px;
  margin: 51px auto 110px auto;

  @media screen and (max-width: 800px) {
    width: 100%;
    height: max-content;
  }
`;
