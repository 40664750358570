/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import Modal from "react-modal";
import { SyncLoader } from "react-spinners";
import { theme } from "styles/theme";

const modalStyle = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        borderRadius: "20px",
        transform: "translate(-50%, -50%)",
        backgroundColor: "rgba(0, 0, 0, 0)",
        border: "none",
    },
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        zIndex: 1,
    },
};
Modal.setAppElement("#root");

type Props = {
    modalIsOpen: boolean;
    closeModal?: () => void;
};

export default function LoadingModal(props: Props) {
    const { modalIsOpen } = props;
    if (modalIsOpen) {
        return (
            <Modal isOpen={modalIsOpen} style={modalStyle}>
                <SyncLoader color={theme.primary500} loading={true} size={15} />
            </Modal>
        );
    } else return null;
}
