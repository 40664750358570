import CalendarIcon from "./CalendarIcon";
import CheckIcon from "./CheckIcon";
import CompanyIcon from "./CompanyIcon";
import InfoIcon from "./InfoIcon";
import RightArrowIcon from "./RightArrowIcon";
import LeftArrowIcon from "./LeftArrowIcon";
import LogoIcon from "./LogoIcon";
import PenIcon from "./PenIcon";
import SettingIcon from "./SettingIcon";
import UserIcon from "./UserIcon";
import WatchIcon from "./WatchIcon";
import DeleteIcon from "./DeleteIcon";
import RefreshIcon from "./RefreshIcon";
import CloseIcon from "./CloseIcon";
import TopArrowIcon from "./TopArrowIcon";
import QuestionIcon from "./QuestionIcon";
import DashBoardIcon from "./DashBoardIcon";
import BookIcon from "./BookIcon";
import DownLoadIcon from "./DownLoadIcon";
export type IconProp = {
    width?: string | number;
    height?: string | number;
    color?: string;
    onClick?: () => void;
};

export type IconNames =
    | "info"
    | "check"
    | "logo"
    | "calendar"
    | "company"
    | "setting"
    | "watch"
    | "user"
    | "right-arrow"
    | "pen"
    | "left-arrow"
    | "delete"
    | "refresh"
    | "close"
    | "top-arrow"
    | "analytics"
    | "book"
    | "download"
    | "question";

export interface MileIconProps {
    name: IconNames;
    width?: string | number;
    height?: string | number;
    color?: string;
    onClick?: () => void;
}

export default function MileIcon({ name, width, height, color, onClick = () => {} }: MileIconProps) {
    switch (name) {
        case "info":
            return <InfoIcon width={width} height={height} color={color} />;
        case "check":
            return <CheckIcon width={width} height={height} color={color} />;
        case "logo":
            return <LogoIcon width={width} height={height} color={color} />;
        case "calendar":
            return <CalendarIcon width={width} height={height} color={color} />;
        case "company":
            return <CompanyIcon width={width} height={height} color={color} />;
        case "setting":
            return <SettingIcon width={width} height={height} color={color} />;
        case "watch":
            return <WatchIcon width={width} height={height} color={color} />;
        case "user":
            return <UserIcon width={width} height={height} color={color} />;
        case "right-arrow":
            return <RightArrowIcon width={width} height={height} color={color} />;
        case "left-arrow":
            return <LeftArrowIcon width={width} height={height} color={color} onClick={onClick} />;
        case "pen":
            return <PenIcon width={width} height={height} color={color} />;
        case "delete":
            return <DeleteIcon width={width} height={height} color={color} />;
        case "refresh":
            return <RefreshIcon width={width} height={height} color={color} />;
        case "close":
            return <CloseIcon width={width} height={height} color={color} onClick={onClick} />;
        case "top-arrow":
            return <TopArrowIcon width={width} height={height} color={color} onClick={onClick} />;
        case "question":
            return <QuestionIcon width={width} height={height} color={color} />;
        case "analytics":
            return <DashBoardIcon width={width} height={height} color={color} />;
        case "book":
            return <BookIcon width={width} height={height} color={color} />;
        case "download":
            return <DownLoadIcon width={width} height={height} color={color} />;
    }
}
