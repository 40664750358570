import React, { useState } from "react";
import { FormContainer, FormSection } from "./form.styles";
import UI from "@mile-meeting/mile-ui";
import { isValidEmail } from "pages/contact-sales";
import { sendIntroductionProposal } from "utils/send-alimtalk";

const meeting_questions = [
  "회의실 부족 문제 해결",
  "회의실 노쇼 문제 해결",
  "회의실 예약 관리 시스템",
  "회의실 사용 현황 파악",
  "기타",
];

const visitors_questions = [
  "수기 방명록 대체",
  "방문자 관리 통한 보안 강화(ISMS/ISO)",
  "방문자 방문 경험 개선",
  "기타",
];

const ProposalSalesForm = () => {
  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>("");
  const [meeting, setMeeting] = useState<boolean>(false);
  const [visitors, setVisitors] = useState<boolean>(false);
  const [agree, setAgree] = useState<boolean>(false);
  const [visitorsList, setVisitorsList] = useState<number[]>([]);
  const [meetingList, setMeetingList] = useState<number[]>([]);
  const [visitorsEtcContent, setVisitorsEtcContent] = useState<string>();
  const [meetingEtcContent, setMeetingEtcContent] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const getErrorMessage = (email: string): { ok: boolean; error: string } => {
    if (email === "") return { ok: false, error: "" };
    else if (isValidEmail(email)) {
      return { ok: true, error: "" };
    } else return { ok: false, error: "올바르지 않은 이메일 형식입니다." };
  };

  const canOnHandleSubmit = () => {
    const meeting_content =
      meeting &&
      meetingList
        .map((item, index) => {
          if (item === 4) return meetingEtcContent;
          return meeting_questions[item];
        })
        .join(", ");

    const visitors_content =
      visitors &&
      visitorsList
        .map((item, index) => {
          if (item === 3) return visitorsEtcContent;
          return visitors_questions[item];
        })
        .join(", ");

    return (
      name &&
      email &&
      isValidEmail(email) &&
      (meeting_content || visitors_content) &&
      agree
    );
  };

  return (
    <>
      <FormContainer>
        <FormSection>
          <p className="label">
            성함<span className="caution">*</span>
          </p>
          <UI.Input
            placeholder="성함을 입력해 주세요."
            onChange={(value) => setName(value)}
          />
        </FormSection>
        <FormSection>
          <p className="label">
            업무용 이메일<span className="caution">*</span>
          </p>
          <UI.Input
            placeholder="ex) mile@mile.im"
            onChange={(value) => setEmail(value)}
            error={email !== "" && !getErrorMessage(email).ok}
            helpText={
              getErrorMessage(email).error
                ? getErrorMessage(email).error
                : undefined
            }
          />
        </FormSection>
        <FormSection>
          <p className="label">
            관심있는 Mile 제품이 무엇인가요?<span className="caution">*</span>
          </p>
          <div className="product-forms">
            <div className="product-form">
              <div className="check-form">
                <UI.CheckBox
                  selected={meeting}
                  onChange={(value) => setMeeting(value)}
                />
                <p className="product-label">Mile Meeting</p>
              </div>
              {meeting && (
                <div className="form">
                  <p className="question">
                    <span className="highlight-text">Mile Meeting</span>
                    <span>에 기대하는 바가 무엇인가요?</span>
                    <span className="annotation">(중복 선택 가능)</span>
                  </p>
                  <div className="check-list">
                    {meeting_questions.map((item, index) => {
                      return (
                        <div className="check-item" key={index}>
                          <UI.CheckBox
                            selected={meetingList.includes(index)}
                            onChange={(value) => {
                              if (value)
                                setMeetingList([...meetingList, index]);
                              else {
                                setMeetingList([
                                  ...meetingList.filter(
                                    (item) => item !== index
                                  ),
                                ]);
                              }
                            }}
                          />
                          <p>{item}</p>
                        </div>
                      );
                    })}
                    {meetingList.includes(4) && (
                      <UI.Input
                        onChange={(value) => setMeetingEtcContent(value)}
                        placeholder="상세히 적어주시면 자세한 안내가 가능합니다."
                        size="small"
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="boundary"></div>
            <div className="product-form">
              <div className="check-form">
                <UI.CheckBox
                  selected={visitors}
                  onChange={(value) => setVisitors(value)}
                />
                <p className="product-label">Mile Visitors</p>
              </div>
              {visitors && (
                <div className="form">
                  <p className="question">
                    <span className="highlight-text">Mile Visitors</span>
                    <span>에 기대하는 바가 무엇인가요?</span>
                    <span className="annotation">(중복 선택 가능)</span>
                  </p>
                  <div className="check-list">
                    {visitors_questions.map((item, index) => {
                      return (
                        <div className="check-item" key={index}>
                          <UI.CheckBox
                            selected={visitorsList.includes(index)}
                            onChange={(value) => {
                              if (value)
                                setVisitorsList([...visitorsList, index]);
                              else {
                                setVisitorsList([
                                  ...visitorsList.filter(
                                    (item) => item !== index
                                  ),
                                ]);
                              }
                            }}
                          />
                          <p>{item}</p>
                        </div>
                      );
                    })}
                    {visitorsList.includes(3) && (
                      <UI.Input
                        onChange={(value) => setVisitorsEtcContent(value)}
                        placeholder="상세히 적어주시면 자세한 안내가 가능합니다."
                        size="small"
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </FormSection>
        <FormSection>
          <div className="agree-check">
            <UI.CheckBox
              selected={agree}
              onChange={(value) => setAgree(value)}
            />
            <p>
              <span
                className="underline"
                onClick={() => {
                  window.location.href =
                    "https://policy.mile.im/landing-marketing.html";
                }}
              >
                마케팅 활용 동의 및 광고 수신
              </span>
              동의
            </p>
          </div>
        </FormSection>
        <UI.Button
          size="large"
          title="Mile 도입 제안서 받기"
          disabled={!canOnHandleSubmit() || loading}
          onClick={async () => {
            setLoading(true);
            const meeting_content =
              meeting &&
              meetingList
                .map((item, index) => {
                  if (item === 4) return meetingEtcContent;
                  return meeting_questions[item];
                })
                .join(", ");

            const visitors_content =
              visitors &&
              visitorsList
                .map((item, index) => {
                  if (item === 3) return visitorsEtcContent;
                  return visitors_questions[item];
                })
                .join(", ");

            const res = await sendIntroductionProposal(
              `${name}`,
              `${email}`,
              meeting,
              `${meeting_content}`,
              visitors,
              `${visitors_content}`,
              agree
            );

            if (res) {
              setLoading(false);
              alert(
                "신청 완료되었습니다. 해당 연락처로 2일 내에 전송 드리겠습니다. "
              );
              window.location.reload();
            }
          }}
        />
      </FormContainer>
    </>
  );
};

export default ProposalSalesForm;
