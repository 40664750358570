/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { MeetingData } from "data/meeting";
import { theme } from "styles/theme";

const Container = styled.div`
  background-color: #fffaf5;
  padding-top: 84px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 662px;
  overflow: hidden;
  @media screen and (max-width: 800px) {
    padding: 84px 12px 0 12px;
    height: max-content;
  }
`;

const Title = styled.p`
  color: ${theme.neutral900};
  font-size: 32px;
  font-weight: 700;
  line-height: 120%; /* 38.4px */

  @media screen and (max-width: 800px) {
    font-size: 26px;
  }
`;

const Annotation = styled.p`
  color: ${theme.neutral600};
  font-size: 20px;
  font-weight: 500;
  line-height: 150%; /* 30px */
  margin-top: 24px;

  @media screen and (max-width: 800px) {
    font-size: 14px;
    text-align: center;
  }
`;

export default function Section4() {
  return (
    <Container>
      <img
        src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/meeting/saas.png"
        css={css`
          width: 210px;
          height: 115px;
          margin-bottom: 16px;

          @media screen and (max-width: 800px) {
            width: 35%;
            height: max-content;
            max-width: 200px;
          }
        `}
      />
      <Title>{MeetingData.연동_설명.제목}</Title>
      <Annotation>{MeetingData.연동_설명.내용}</Annotation>
      <img
        src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/meeting/img5.png"
        css={css`
          width: 665px;
          height: 409px;
          margin-top: 30px;

          @media screen and (max-width: 800px) {
            width: 100%;
            height: max-content;
          }
        `}
      />
    </Container>
  );
}
