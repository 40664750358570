import React from "react";
import { IconProp } from ".";
export default function DeleteIcon(props: IconProp) {
    const { width = 24, height = 24, color = "black" } = props;
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 24 24" fill="none">
                <circle cx="12" cy="12" r="10" fill={color} />
                <path d="M8 12L16 12" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div>
    );
}
