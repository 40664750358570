/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "styles/theme";
import { Card } from "../uis";
import { MeetingData } from "data/meeting";

const Container = styled.div`
  background-color: ${theme.neutral50};
  padding: 120px 0;

  @media screen and (max-width: 800px) {
    padding: 80px 12px;
  }
`;

const FN_LIST = [
  {
    title: "노쇼 정책 설정",
    content: "회의실 노쇼 정책을 설정하면, 노쇼 처리된 회의가 취소되어 유휴 공간을 확보할 수 있습니다.",
    icon: (
      <img
        src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/meeting/functions/group_off.png"
        css={css`
          width: 40px;
          height: 40px;
        `}
      />
    ),
  },
  {
    title: "자동 체크인/체크아웃 기능",
    content: "회의실 내 음성을 감지하여, 자동으로 회의를 시작 및 종료할 수 있습니다.",
    icon: (
      <img
        src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/meeting/functions/adaptive_audio_mic.png"
        css={css`
          width: 40px;
          height: 40px;
        `}
      />
    ),
  },
  {
    title: "메신저 알림",
    content: "회의 시작 전 알림, 연장 알림 등 회의를 진행하는데 필요한 알림 등을 메신저를 통해 받아볼 수 있습니다.",
    icon: (
      <img
        src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/meeting/functions/feedback.png"
        css={css`
          width: 40px;
          height: 40px;
        `}
      />
    ),
  },
  {
    title: "타이머 ON/OFF",
    content: "남은 시간을 확인하시고 싶으신가요? 상황에 따라 유연하게 태블릿 내 타이머를 ON/OFF 할 수 있습니다.",
    icon: (
      <img
        src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/meeting/functions/avg_pace.png"
        css={css`
          width: 40px;
          height: 40px;
        `}
      />
    ),
  },
  {
    title: "민원 기능",
    content: "태블릿으로 간편하게 회의실에서 발생한 민원을 등록하고 처리할 수 있습니다.",
    icon: (
      <img
        src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/meeting/functions/notification_multiple.png"
        css={css`
          width: 40px;
          height: 40px;
        `}
      />
    ),
  },
  {
    title: `더 많은 기능이\n추가될 예정입니다.`,
    content: "",
    icon: (
      <img
        src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/visitors/functions/more_horiz.png"
        css={css`
          width: 28px;
          height: 8px;
        `}
      />
    ),
  },
];
export default function Section3() {
  return (
    <Container>
      <div
        css={css`
          display: grid;
          width: 1060px;
          grid-template-columns: 1fr 1fr 1fr;
          column-gap: 20px;
          row-gap: 40px;
          margin: 0 auto;

          @media screen and (max-width: 800px) {
            grid-template-columns: 1fr 1fr;
            width: 100%;
            column-gap: 12px;
            row-gap: 16px;
          }
        `}
      >
        {MeetingData.기능_리스트.map((item, index) => {
          return (
            <Card
              key={index}
              title={item.제목}
              content={item.내용}
              icon={<img src={item.아이콘} width={item.아이콘_너비} height={item.아이콘_높이} />}
            />
          );
        })}
      </div>
    </Container>
  );
}
