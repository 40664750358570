/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
import { theme } from "styles/theme";
import Footer from "uis/component/footer";
import FreeTrialBtn from "uis/component/free-trial-btn";
import Header from "uis/component/header";
import StartModal from "./start-modal";
import EmailModal, { sendEmail } from "./email-modal";
import { useLocation } from "react-router-dom";
import { gaEvent } from "utils/ga-utils";

const Container = styled.div`
    background-color: white;
    padding-top: 250px;
    padding-bottom: 200px;
    display: flex;
    flex-direction: column;
    max-width: 1100px;
    margin: 0 auto;

    @media screen and (max-width: 800px) {
        margin: 0 12px;
        padding-top: 120px;
    }
`;

const TutorialContainer = styled.a`
    width: 1100px;
    margin: 0 auto;
    position: relative;

    @media screen and (max-width: 800px) {
        width: 100%;
    }
`;

const IFrame = styled.iframe`
    width: 1100px;
    height: 600px;
    border-radius: 30px;
    z-index: 0;

    @media screen and (max-width: 800px) {
        border-radius: 20px;
        width: 100%;
        height: 100%;
    }
`;

const Title = styled.div`
    color: ${theme.neutral900};
    font-size: 50px;
    font-weight: 800;
    line-height: 150%;
    border-bottom: 2px solid ${theme.neutral100};

    @media screen and (max-width: 800px) {
        font-size: 25px;
    }
`;

const Label = styled.p`
    color: ${theme.neutral600};
    font-size: 30px;
    font-weight: 700;
    line-height: 150%;
    margin-top: 120px;
    margin-bottom: 30px;

    @media screen and (max-width: 800px) {
        font-size: 20px;
        margin-top: 40px;
        margin-bottom: 12px;
    }
`;

export default function Tutorial() {
    const [visitorStart, setVisitorStart] = useState<boolean>(true);
    const [meetingStart, setMeetingStart] = useState<boolean>(true);
    const [analyticsStart, setAnalyticsStart] = useState<boolean>(true);

    const [showEmailVisitors, setShowEmailVisitors] = useState<boolean>(false);
    const [showEmailMeeting, setShowEmailMeeting] = useState<boolean>(false);

    const url = useLocation();

    const onStartVisitors = () => {
        setVisitorStart(false);
        gaEvent("Mile 서비스 체험하기-Mile Visitors-시작하기");
        const email = localStorage.getItem("tutorial-email");
        if (email) {
            sendEmail(email, "visitors", "/" + url.search);
        } else {
            setTimeout(() => {
                setShowEmailVisitors(true);
            }, 13000);
        }
    };

    const onStartMeeting = () => {
        setMeetingStart(false);
        gaEvent("Mile 서비스 체험하기-Mile Meeting-시작하기");
        const email = localStorage.getItem("tutorial-email");
        if (email) {
            sendEmail(email, "meeting", "/" + url.search);
        } else {
            setTimeout(() => {
                setShowEmailMeeting(true);
            }, 10000);
        }
    };

    const onStartAnalytics = () => {
        setAnalyticsStart(false);
        gaEvent("Mile 서비스 체험하기-Mile Analytics-시작하기");
        const email = localStorage.getItem("tutorial-email");
        if (email) {
            sendEmail(email, "meeting", url.search);
        }
    };

    return (
        <>
            <Header />
            <Container>
                <Title>Mile 서비스 체험하기</Title>
                <Label>Mile Visitors (방문자 관리 솔루션)</Label>
                <TutorialContainer>
                    <IFrame
                        src="https://demo.arcade.software/skb5flHVXraDmFka2vSf?embed&show_copy_link=true"
                        title="마일 visitors 튜토리얼_나의 방문관"
                        frameBorder="0"
                        loading="lazy"
                        allowFullScreen={false}
                        allow="clipboard-write"
                    />
                    <StartModal
                        onStart={onStartVisitors}
                        isShow={visitorStart}
                        title="Mile Visitors 체험하기"
                        functions={["방문 일정 예약", "방문 일정 관리", "서비스 커스터마이징"]}
                    />
                    <EmailModal
                        isShow={showEmailVisitors}
                        product="visitors"
                        onSubmit={(email) => {
                            setShowEmailVisitors(false);
                            localStorage.setItem("tutorial-email", email);
                        }}
                    />
                </TutorialContainer>
                <Label>Mile Meeting (회의실 관리 솔루션)</Label>
                <TutorialContainer>
                    <IFrame
                        src="https://demo.arcade.software/rMcouuEORBQEOCvhslqH?embed&show_copy_link=true"
                        title="마일Meeting 튜토리얼"
                        frameBorder="0"
                        loading="lazy"
                        allowFullScreen={false}
                        allow="clipboard-write"
                    />
                    <StartModal
                        onStart={onStartMeeting}
                        isShow={meetingStart}
                        title="Mile Meeting 체험하기"
                        functions={["회의실 정책 설정", "회의실 민원 관리"]}
                    />
                    <EmailModal
                        isShow={showEmailMeeting}
                        product="meeting"
                        onSubmit={(email) => {
                            setShowEmailMeeting(false);
                            localStorage.setItem("tutorial-email", email);
                        }}
                    />
                </TutorialContainer>
                <Label>Mile Analytics (데이터 통합 분석 솔루션)</Label>
                <TutorialContainer>
                    <IFrame
                        src="https://demo.arcade.software/6uTtkV6D9XUUSp5r0g7D?embed&show_copy_link=true"
                        title="마일_Analytics"
                        frameBorder="0"
                        loading="lazy"
                        allowFullScreen={false}
                        allow="clipboard-write"
                    />
                    <StartModal
                        onStart={onStartAnalytics}
                        isShow={analyticsStart}
                        title="Mile Analytics 체험하기"
                        functions={["Mile Meeting 대시보드", "Mile Visitors 대시보드"]}
                    />
                </TutorialContainer>
            </Container>
        </>
    );
}
