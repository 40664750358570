/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { theme } from "styles/theme";
import { css } from "@emotion/react";
import { useRef, useState } from "react";

export const Label = styled.p`
  color: ${theme.primary500};
  font-size: 24px;
  font-weight: 800;
  line-height: 150%;

  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
`;

export const Title = styled.p`
  color: ${theme.neutral900};
  font-size: 44px;
  font-weight: 800;
  line-height: 120%;
  margin-top: 16px;
  white-space: nowrap;

  @media screen and (max-width: 800px) {
    font-size: 30px;
  }
`;

export const Content = styled.p`
  font-size: 22px;
  color: ${theme.neutral900};
  font-weight: 500;
  line-height: 150%; /* 33px */
  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
`;

export const List = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;
