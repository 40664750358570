import React from "react";
import { IconProp } from ".";
export default function InfoIcon(props: IconProp) {
    const { width = 24, height = 24, color = "black" } = props;
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.7069 5.29327C16.8944 5.4808 16.9997 5.73511 16.9997 6.00027C16.9997 6.26544 16.8944 6.51975 16.7069 6.70727L8.70692 14.7073C8.51939 14.8947 8.26508 15.0001 7.99992 15.0001C7.73476 15.0001 7.48045 14.8947 7.29292 14.7073L3.29292 10.7073C3.11076 10.5187 3.00997 10.2661 3.01224 10.0039C3.01452 9.74168 3.11969 9.49086 3.3051 9.30546C3.49051 9.12005 3.74132 9.01488 4.00352 9.0126C4.26571 9.01032 4.51832 9.11112 4.70692 9.29327L7.99992 12.5863L15.2929 5.29327C15.4804 5.1058 15.7348 5.00049 15.9999 5.00049C16.2651 5.00049 16.5194 5.1058 16.7069 5.29327Z"
                    fill={color}
                />
            </svg>
        </div>
    );
}
