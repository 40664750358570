import React from "react";
import { IconProp } from ".";
export default function RightArrowIcon(props: IconProp) {
    const { width = 24, height = 24, color = "black" } = props;
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
                <path d="M9 5L16 12L9 19" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div>
    );
}
