export const theme = {
    // button
    button: {
        primary: "#3885FF",
        secondary: "#EBF5FF",
        destructive: "#FFF6F6",
    },
    //font
    font: {
        white: "white",
        primary: "#3885FF",
        destructive: "#FF3E3E",
    },

    //neutral
    neutral900: "#1B1D1F",
    neutral800: "#26282b",
    neutral600: "#454C53",
    neutral500: "#6e7781",
    neutral400: "#A0A8B0",
    neutral300: "#B6BDC6",
    neutral200: "#CBD1D9",
    neutral100: "#EEF1F3",
    neutral50: "#F7F8F9",

    //primary
    primary50: "#EBF5FF",
    primary100: "#DCEEFF",
    primary300: "#80BAFF",
    primary500: "#3885FF",
};
