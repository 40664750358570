/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "styles/theme";

const Container = styled.div`
    padding: 200px 132px 200px 170px;
    width: 1138px;
    display: flex;
    flex-direction: column;
    gap: 160px;
    margin: 0 auto;

    @media screen and (max-width: 800px) {
        gap: 80px;
        width: 100%;
        padding: 24px 0;
    }
`;

const ArticleContainer = styled.div`
    display: flex;
    flex-direction: column;

    h3 {
        color: ${theme.neutral900};
        font-size: 40px;
        font-weight: 700;
        line-height: 120%; /* 48px */
        letter-spacing: -0.4px;
        margin-top: 16px;
        margin-bottom: 24px;

        @media screen and (max-width: 800px) {
            font-size: 24px;
            margin-top: 8px;
            margin-bottom: 12px;
        }
    }

    p {
        color: ${theme.neutral600};
        font-size: 18px;
        font-weight: 500;
        line-height: 150%; /* 30px */

        @media screen and (max-width: 800px) {
            font-size: 16px;
            margin-bottom: 24px;
        }
    }
`;

const SectionContainer = styled.div`
    width: 100%;
    display: flex;
    position: relative;

    @media screen and (max-width: 800px) {
        flex-direction: column;
        margin: 0 12px;
        width: calc(100% - 24px);
    }
`;

const Tag = styled.div`
    padding: 8px 16px;
    width: max-content;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    border: 1px solid ${theme.neutral100};
    background-color: ${theme.primary50};

    color: ${theme.primary500};
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.16px;

    @media screen and (max-width: 800px) {
        padding: 4px 8px;
        font-size: 12px;
    }
`;

export default function Section2() {
    return (
        <Container>
            <SectionContainer
                css={css`
                    @media screen and (max-width: 800px) {
                        flex-direction: column-reverse;
                    }
                `}
            >
                <img
                    src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/analytics/img1.png"
                    css={css`
                        width: 882px;
                        height: 605px;
                        position: absolute;
                        top: 0;
                        left: 0;

                        @media screen and (max-width: 800px) {
                            position: relative;
                            width: 100%;
                            height: max-content;
                            max-width: 450px;
                        }
                    `}
                />
                <ArticleContainer
                    css={css`
                        margin-left: auto;
                        margin-top: 90px;
                        margin-bottom: 321px;

                        @media screen and (max-width: 800px) {
                            margin: 0;
                        }
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            gap: 12px;
                        `}
                    >
                        <Tag>Mile Meeting</Tag>
                        <Tag>Mile Visitors</Tag>
                    </div>
                    <h3>사용 현황 대시보드</h3>
                    <p>
                        다양한 데이터를 한눈에 확인하여 비즈니스
                        <br />
                        인사이트 및 공간 운영을 위한 정책을 도출해보세요.
                    </p>
                </ArticleContainer>
            </SectionContainer>
            <SectionContainer>
                <ArticleContainer
                    css={css`
                        margin: 175px 85px 175px 0;

                        @media screen and (max-width: 800px) {
                            margin: 0;
                        }
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            gap: 12px;
                        `}
                    >
                        <Tag>Mile Meeting</Tag>
                        <Tag>Mile Visitors</Tag>
                    </div>
                    <h3>데이터 필터 기능</h3>
                    <p>
                        우리 회사에서 발생하는 공간&방문자 데이터에
                        <br />
                        필터(기간별, 항목별)를 적용하여 디테일한 조건에
                        <br />
                        따른 데이터를 확인할 수 있습니다.
                    </p>
                </ArticleContainer>
                <img
                    src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/analytics/img2.png"
                    css={css`
                        width: 610px;
                        height: 560px;

                        @media screen and (max-width: 800px) {
                            position: relative;
                            width: 100%;
                            height: max-content;
                            max-width: 450px;
                        }
                    `}
                />
            </SectionContainer>
            <SectionContainer
                css={css`
                    @media screen and (max-width: 800px) {
                        flex-direction: column-reverse;
                    }
                `}
            >
                <img
                    src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/analytics/img3.png"
                    css={css`
                        width: 630px;
                        height: 760px;
                        position: absolute;
                        left: 0;
                        top: -200px;

                        @media screen and (max-width: 800px) {
                            position: relative;
                            width: 100%;
                            height: max-content;
                            top: -30px;
                            max-width: 450px;
                        }
                    `}
                />
                <ArticleContainer
                    css={css`
                        margin: 112px 24px 122px auto;
                        @media screen and (max-width: 800px) {
                            margin: 0;
                        }
                    `}
                >
                    <Tag>Mile Meeting</Tag>
                    <h3>임직원 관계망 데이터</h3>
                    <p
                        css={css`
                            white-space: pre-line;
                        `}
                    >
                        일정기간 동안 누구와 미팅을 가졌는지에 대한{"\n"}데이터를 통해 임직원들의 관계망을 파악할 수{"\n"}있습니다.
                    </p>
                    <p
                        css={css`
                            margin-top: 16px;
                            white-space: pre-line;
                        `}
                    >
                        해당 데이터는 성과 평가, 피어 리뷰,{"\n"}1-on-1 대상자 선정 등 다양한 용도로 활용될 수{"\n"}있습니다.
                    </p>
                </ArticleContainer>
            </SectionContainer>
        </Container>
    );
}
