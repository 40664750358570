import React from "react";
import { IconProp } from ".";
export default function DeleteIcon(props: IconProp) {
    const { width = 24, height = 24, color = "black" } = props;
    if (color === "black") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.5 3C2.67157 3 2 3.67157 2 4.5C2 5.15311 2.4174 5.70873 3 5.91465V15C3 16.6569 4.34315 18 6 18H8.94971L6.70707 20.2426C6.31654 20.6332 6.31654 21.2663 6.70707 21.6569C7.09759 22.0474 7.73076 22.0474 8.12128 21.6569L11.6568 18.1213C11.6654 18.1127 11.6738 18.1041 11.682 18.0952C11.6902 18.104 11.6985 18.1127 11.7071 18.1213L15.2426 21.6568C15.6332 22.0473 16.2663 22.0473 16.6569 21.6568C17.0474 21.2663 17.0474 20.6331 16.6569 20.2426L14.4142 18H18C19.6569 18 21 16.6569 21 15V5.91465C21.5826 5.70873 22 5.15311 22 4.5C22 3.67157 21.3284 3 20.5 3H3.5Z"
                    fill="white"
                />
                <rect x="7" y="12" width="2" height="3" rx="1" fill="#1B1D1F" />
                <rect x="11" y="9" width="2" height="6" rx="1" fill="#1B1D1F" />
                <rect x="15" y="7" width="2" height="8" rx="1" fill="#1B1D1F" />
            </svg>
        );
    } else {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.5 3C2.67157 3 2 3.67157 2 4.5C2 5.15311 2.4174 5.70873 3 5.91465V15C3 16.6569 4.34315 18 6 18H8.94971L6.70707 20.2426C6.31654 20.6332 6.31654 21.2663 6.70707 21.6569C7.09759 22.0474 7.73076 22.0474 8.12128 21.6569L11.6568 18.1213C11.6654 18.1127 11.6738 18.1041 11.682 18.0952C11.6902 18.104 11.6985 18.1127 11.7071 18.1213L15.2426 21.6568C15.6332 22.0473 16.2663 22.0473 16.6569 21.6568C17.0474 21.2663 17.0474 20.6331 16.6569 20.2426L14.4142 18H18C19.6569 18 21 16.6569 21 15V5.91465C21.5826 5.70873 22 5.15311 22 4.5C22 3.67157 21.3284 3 20.5 3H3.5Z"
                    fill="#3885FF"
                />
                <rect x="7" y="12" width="2" height="3" rx="1" fill="white" />
                <rect x="11" y="9" width="2" height="6" rx="1" fill="white" />
                <rect x="15" y="7" width="2" height="8" rx="1" fill="white" />
            </svg>
        );
    }
}
